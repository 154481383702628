<html>

  <head>
      <title></title>
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1">
      <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1" />

       <!-- Los iconos tipo Solid de Fontawesome-->
    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.0.8/css/solid.css">
    <script src="https://use.fontawesome.com/releases/v5.0.7/js/all.js"></script>
          
  </head>
  
   <!-- Tabla para backg -->
   <body style=" margin: 0 !important; padding: 0 !important;">
      <!-- HIDDEN PREHEADER TEXT -->

      <!-- Gif Loading-->
      <div *ngIf="active"class="contenedorLoading"></div>
      <div *ngIf="active"class="contenedorLoading1">
        <div class="loading"><img src="assets/img/loading.gif" width="200px" alt="splash" ></div>
      </div>

      <!-- Mensaje advertencia -->
      <div *ngIf="advertencia"class="contenedoradvertencia"></div>
      <div *ngIf="advertencia"class="contenedoradvertencia1">
        <div class="contenedorMensaje"> 
          <div class="btonalarma"><img type="button" (click)="onClickMe()" src="assets/img/btn_warning.png" width= 45px; alt=""></div>
          <div class ="encabezadoalarma"><img src="assets/img/en_warningp.png" width=375px; alt="warning">  </div>
          <div class="cuerpoalarma" style="font-family:'AvenirLTProMedium'; font-size: 25px; text-align: center;"> <p>{{msj}}</p></div>
        </div>
      </div>
      <!-- Fin mensaje advertencia -->

      <!-- Mensaje Error contrasena-->
      <div *ngIf="errorc"class="contenedoradvertencia"></div>
      <div *ngIf="errorc"class="contenedoradvertencia1">
        <div class="contenedorMensaje"> 
          <div class="btonalarma"><img type="button" (click)="onClickMe()" src="assets/img/btn_error.png" width= 45px; alt=""></div>
          <div class ="encabezadoalarma"><img src="assets/img/en_errorp.png" width=375px; alt="error">  </div>
          <div class="cuerpoalarma" style="font-family:'AvenirLTProMedium'; font-size: 25px;text-align: center;" > <p>{{msj}}</p></div>
        </div>
      </div>
      <!-- Fin mensaje Error contrasena-->

        <!-- Mensaje Exitoso -->
        <div *ngIf="exitoso"class="contenedoradvertencia"></div>
        <div *ngIf="exitoso"class="contenedoradvertencia1">
          <div class="contenedorMensaje"> 
            <div class="btonalarma"><img type="button" (click)="onClickMe()" src="assets/img/btn_exitoso.png" width= 45px; alt=""></div>
            <div class ="encabezadoalarma"><img src="assets/img/exitosod.png" width=375px; alt="error">  </div>
            <div class="cuerpoalarma" style="font-family:'AvenirLTProMedium'; font-size: 25px;text-align: center;" > <p>{{msj}}</p></div>
          </div>
        </div>
        <!-- Fin mensaje Exitoso-->
        
      <table border="0" cellpadding="0" cellspacing="0" width="100%">
   
        <!-- Tabla encabezado-->
          <tr>
            <td align="left">
              <div class="figure">
                <img src="assets/img/icrealog.png" width="200"alt="logoicrea">
              </div>
              
                <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 375px;">
                    <tr>
                        <td align="center" valign="top" style="padding: 50px 20px 0px 20px;"> </td>
                    </tr>
                </table>
            </td>
        </tr>
        <!-- Fin tabla de encabezado-->

        <tr *ngIf="activeICREA">  <!--  encabezado-->
          <td  align="center" style="padding: 0px 20px 0px 20px;">
              <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width:375px;">
                  <tr>
                     <td class="opacity" bgcolor="#D1D5DB" align="center" valign="top" style="padding: 40px 20px 20px 20px; border-radius: 20px 20px 0px 0px; color: #2B405A; font-family:'AvenirLTProBlack';">
                        <div class="splash" id="splash"> 
                            <img src="assets/img/splashscreen.gif" width="200px" alt="splash">
                        </div>
                      </td>
                  </tr>
              </table>
          </td>
      </tr>  <!-- Fin encabezado-->
  
      <!-- cuerpo formulario-->
      <tr *ngIf="activeICREA">
        <td  align="center" style="padding: 0px 20px 7.85% 20px;">
            <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 375px;">
                <tr>
                    <td class="opacity" bgcolor="#D1D5DB" align="center" style="padding: 0px 15px 0px 15px; color: #2B405A; font-family: 'AvenirLTProMediumOblique'; font-size: 18px;">

                        
                    </td>
                </tr>
                <tr>
                    <td class="opacity" bgcolor="#D1D5DB" align="right" style="padding: 20px 0px 8px 15px; border-radius: 0px 0px 20px 20px; color: #2B405A; font-family: 'AvenirLTProMedium'; font-size: 18px; font-weight: 400; line-height: 25px;">
                    
                    </td>
                </tr>
            </table>
        </td>
    </tr>





         <!-- Tabla formulario-->
  
        <tr *ngIf="activeFormulario">  <!--  encabezado-->
          <td  align="center" style="padding: 0px 20px 0px 20px;">
              <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width:375px;">
                  <tr>
  
                     <td class="opacity" bgcolor="#D1D5DB" align="center" valign="top" style="padding: 0px 20px 0px 20px; border-radius: 20px 20px 0px 0px; color: #2B405A; font-family:'AvenirLTProBlack';">
                      <div style="color:#2B405A;font-size:30px; margin-top:25px; margin-bottom: 10px;">BIENVENIDO </div>
                      </td>
                  </tr>
              </table>
          </td>
      </tr>  <!-- Fin encabezado-->

      
  
      <!-- cuerpo formulario-->
      <tr *ngIf="activeFormulario">
        <td align="center" style="padding: 0px 20px 7.8% 20px;">
            <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 375px;">
                <tr>
                    <td class="opacity" bgcolor="#D1D5DB" align="center" style="padding: 10px 15px 0px 15px; color: #2B405A; font-family: 'AvenirLTProMediumOblique'; font-size: 18px;">
                     <!-- Validacion correo-->
                      <form [formGroup]="contactForm" (ngSubmit)="onSaveForm()" class="col-12">
                        <div class="form-group" id="user-group">
                            <input type="text" class="form-control" placeholder="Correo" formControlName="email" [(ngModel)]="login.correo" name="correo" >
                            <div class="alert-danger" *ngIf="email.invalid && (email.dirty || email.touched)">
                              <!--*ngIf="email.invalid && (email.dirty || email.touched)-->
                              <div class="container-error-message" style="font-size: 15px;" *ngIf="email.errors.required">
                                <!--  *ngIf="email.errors.required"-->
                                  El correo es requerido.
                              </div>
                            
                              <div class="container-error-message" style="font-size: 15px;" *ngIf="(email.dirty || email.touched) && email.invalid && email.errors.pattern">
                                <!--  *ngIf="(email.dirty || email.touched) && email.invalid && email.errors.pattern"-->
                                No es una direccion de correo valida.
                              </div>
                            </div>
                        </div>
                        <!-- fin validacion correo-->
  
                        <!-- Contrasena-->
                        <div class="form-group" id="contrasena-group">
                          <input matInput type="password" class="form-control" placeholder="Contraseña" [type]= "hide ? 'password': 'text' "formControlName="password" [(ngModel)]="login.pass" name="password" >
                          <i style="cursor:pointer; position: absolute;" class= "material-icons" matSuffix (click)="hide = !hide"  >
                            {{hide ? 'visibility':'visibility_off'}}    
                            </i>

                          <div class="alert-danger" *ngIf="password.invalid && (password.dirty || password.touched)">
                            <div class="container-error-message" style="font-size: 15px;" *ngIf="password.errors.required">
                              La contraseña es requerida.
                            </div>
                            <div class="container-error-message"  style="font-size: 15px;" *ngIf="password.errors.minlength">
                              debe tener al menos 5 caracteres.
                            </div>
                          </div>
                          
                      
                        </div>
  
                        <!-- Fin validacion Contrasena-->
                        
                        <button type="submit" class="btn btn-dark" style="align-content:center; height:40px; font-family: 'AvenirLTProBlack';font-size:16px; width:310px;border-radius:50px;">INGRESAR</button>
                      
                    </form>
        
                    </td>
                </tr>
                <tr>
                    <td class="opacity" bgcolor="#D1D5DB" align="right" style="padding: 0px 0px 8px 15px; border-radius: 0px 0px 20px 20px; color: #2B405A; font-family: 'AvenirLTProMedium'; font-size: 18px; font-weight: 400; line-height: 25px;">
                      <div class="col-12 olvidopas">
                        ¿Olvidó su contraseña? <a type="button" (click)="mostrarOlvidoC()"  style="color:#2B405A "><b>Clic aquí.</b></a>
                        <!-- ¿Olvidó su contraseña? <a href="#"  style="color:#2B405A ">Clic aquí.</a> -->
                    </div>
                    </td>
                </tr>
            </table>
        </td>
    </tr>
  
      <!--fin cuerpo formulario-->
      <!-- Fin tabla formulario-->
  
      <!--Insignia-->
      <tr>
        <td  align="right" style="padding: 0px 30px 0px 20px;">
          <table border="0" cellpadding="0" cellspacing="0" width="100%" >
            <tr>
                <td class="insignia" align="right" style="font-family:'AvenirLTProMediumOblique'; font-size: 18px;"> <br>
                  <h1 style="font-family:'AvenirLTProMediumOblique';">
                      ¡Nos reinventamos! Somos una organización libre de papel, amigable con el medio ambiente.<br/>
                      Nuestros procesos ahora son digitales manteniendo nuestros altos estándares de calidad,<br/>
                      confiabilidad y compromiso con nuestros clientes y empleados.<br/>
                      Audit-ICREA siempre contigo.
                  </h1> 
                </td>
            </tr>
          </table>
        </td>
      </tr>
      
    <!-- Fin insignia--->
  </table>

  
  

  </body>

  
  </html>
  
  