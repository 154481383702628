
<html>

    <head>
        <title></title>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1">
        <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1" />
  
         <!-- Los iconos tipo Solid de Fontawesome-->
      <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.0.8/css/solid.css">
      <script src="https://use.fontawesome.com/releases/v5.0.7/js/all.js"></script>
            
    </head>
    
     <!-- Tabla para backg -->
     <body style=" margin: 0 !important; padding: 0 !important;">
        <!-- HIDDEN PREHEADER TEXT -->

          
        <table border="0" cellpadding="0" cellspacing="0" width="100%">
     
          <!-- Tabla encabezado-->
            <tr>
              <td align="left">
                <div class="figure">
                  <img src="assets/img/icrealog.png" width="150" height="100"  alt="logoicrea">
                </div>
                
                  <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 375px;">
                      <tr>
                          <td align="center" valign="top" style="padding: 50px 20px 0px 20px;"> </td>
                      </tr>
                  </table>
              </td>
          </tr>
          <!-- Fin tabla de encabezado-->
           <!-- Tabla formulario-->
    
          <tr>  <!--  encabezado-->
            <td  align="center" style="padding: 0px 20px 0px 20px;">
                <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width:375px;">
                    <tr>
    
                       <td class="opacity" bgcolor="#D1D5DB" align="center" valign="top" style="padding: 0px 20px 0px 20px; border-radius: 20px 20px 0px 0px; color: #2B405A; font-family:'AvenirLTProBlack';">
                        <div  style="color:#2B405A;font-size:23px; margin-top:30px; margin-bottom: 10px;">RECUPERAR CONTRASEÑA </div>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>  <!-- Fin encabezado-->
    
        <!-- cuerpo formulario-->
        <tr>
          <td  align="center" style="padding: 0px 20px 54px 20px;">
              <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 375px;">
                  <tr>
                      <td class="opacity" bgcolor="#D1D5DB" align="center" style="padding: 10px 15px 0px 15px; color: #66191A; font-family: 'AvenirLTProLight'; font-size: 20px;">
                       <!-- Validacion correo-->
                        <form class="col-12">
                        <div style="background-color:#F3CDCD; border-radius: 10px;">
                            
                            <p style="padding: 10px 25px 10px 25px;"> Hola Carolina Albornoz  La solicitud de contraseña provisional fue realizada exitosamente, ya puede iniciar sesión en la aplicación Audit-ICREA .</p>
                        </div>
                                                                                  
                      </form>
          
                      </td>
                  </tr>
                  <tr>
                    <td class="opacity" bgcolor="#D1D5DB" align="center" style="padding: 10px 0px 8px 15px; border-radius: 0px 0px 20px 20px; color: #2B405A; font-family: 'AvenirLTProLight'; font-size: 18px; font-weight: 400; line-height: 25px;">
                        <div class="col-12 olvidopas">
                          <a href="/inicio"  style="color:#0B5077;">Ir al sitio de Audit-ICREA</a>
                          <!-- ¿Olvidó su contraseña? <a href="#"  style="color:#2B405A ">Clic aquí.</a> -->
                      </div>
                      </td>
                  </tr>
              </table>
          </td>
      </tr>
    
        <!--fin cuerpo formulario-->
        <!-- Fin tabla formulario-->
    
        <!--Insignia-->
     
    
      <tr>
        <td  align="right" style="padding: 0px 20px 0px 20px;">
            <table border="0" cellpadding="0" cellspacing="0" width="100%" >
                <tr>
                    <td class="insignia" align="right" style="padding: 0px 25px 20px 25px; color: #FFFF; font-family:'AvenirLTProMediumOblique'; font-size: 18px;"> <br>
                      <h1 style="font-family:'AvenirLTProMediumOblique';">
                          ¡Nos reinventamos! Somos una empresa libre de papel, amigable con el medio ambiente.<br/>
                          Nuestros procesos ahora son digitales manteniendo nuestros altos estándares de calidad,<br/>
                          confiabilidad y compromiso con nuestros clientes y empleados.<br/>
                          Audit-ICREA siempre contigo.
                      </h1> 
                    </td>
                </tr>
            </table>
        </td>
    </tr>
      <!-- Fin insignia--->
    
    </table>
    
  
    </body>
  
    
    </html>
    
    
