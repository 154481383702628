
  <!-- Gif Loading-->
  <div *ngIf="active"class="contenedoradvertencia"></div>
  <div *ngIf="active"class="contenedoradvertencia1">
    <div class="cuerpoalarma" style="font-family:'AvenirLTProMedium'; font-size: 25px; text-align: center;">
       
        <div style="font-family: 'AvenirLTProMedium'; font-size: 15px; margin-top: 10px;"><td *ngIf="rol1111"><img src="../../assets/img/ic_superusuario.png"  mat-raised-button
            matTooltip="Superusuario" style="width: 30px; padding-right: 5px;"> Super usuario</td></div>
     
            <div style="font-family: 'AvenirLTProMedium'; font-size: 15px; margin-top: 10px;">
        <td *ngIf="rol21"><img src="../../assets/img/ic_administrador.png"  mat-raised-button
            matTooltip="Administrador" style="width: 30px; padding-right: 5px;"> Administrador</td>
      </div>
      <div style="font-family: 'AvenirLTProMedium'; font-size: 15px; margin-top: 10px;">
        <td *ngIf="rol31"><img src="../../assets/img/ic_usuario_norma.png"  mat-raised-button
            matTooltip="Usuario norma" style="width: 30px;padding-right: 5px;"> Usuario norma</td>
      </div>
      <div style="font-family: 'AvenirLTProMedium'; font-size: 15px; margin-top: 10px;">
        <td *ngIf="rol41"><img src="../../assets/img/ic_usuario_pregunta.png" mat-raised-button
            matTooltip="Usuario preguntas" style="width: 30px; padding-right: 5px;"> Usuario preguntas</td>
      </div>

        <div style="font-family: 'AvenirLTProMedium'; font-size: 15px; margin-top: 10px;">
            <td *ngIf="rol51"><img src="../../assets/img/ic_usuario_sugerencia.png"  mat-raised-button
                matTooltip="Usuario sugerencias" style="width: 30px; padding-right: 5px;"> Usuario sugerencias</td> 
        </div>
        <div style="font-family: 'AvenirLTProMedium'; font-size: 15px; margin-top: 10px;">
            <td *ngIf="rol61"><img src="../../assets/img/ic_usuario_solucion.png"  mat-raised-button
                matTooltip="Usuario soluciones" style="width: 30px; padding-right: 5px;"> Usuario soluciones</td>
        </div>
    <div style="font-family: 'AvenirLTProMedium'; font-size: 15px; margin-top: 10px;"> 
        <td *ngIf="rol71"><img src="../../assets/img/ic_auditor_lider.png"  mat-raised-button
            matTooltip="Auditor lider" style="width: 30px; padding-right: 5px;"> Auditor lider</td>
    </div>

    <div style="font-family: 'AvenirLTProMedium'; font-size: 15px;margin-top: 10px;">
        <td *ngIf="rol81"><img src="../../assets/img/ic_electrico.png"  mat-raised-button
            matTooltip="Auditor eléctrico" style="width: 30px; padding-right: 5px;"> Auditor eléctrico</td>
    </div>

    <div style="font-family: 'AvenirLTProMedium'; font-size: 15px;margin-top: 10px;">
        <td *ngIf="rol91"><img src="../../assets/img/ic_clima.png" mat-raised-button
            matTooltip="Auditor clima" style="width: 30px; padding-right: 5px;"> Auditor clima</td>
    </div>

    <div style="font-family: 'AvenirLTProMedium'; font-size: 15px;margin-top: 10px;">
        <td *ngIf="rol101"><img src="../../assets/img/ic_seguridad.png"  mat-raised-button
            matTooltip="Auditor seguridad"style="width: 30px; padding-right: 5px;"> Auditor seguridad</td>
    </div>

    <div style="font-family: 'AvenirLTProMedium'; font-size: 15px;margin-top: 10px;">
        <td *ngIf="rol111"><img src="../../assets/img/ic_comunicaciones.png"  mat-raised-button
            matTooltip="Auditor comunicaciones"style="width: 30px; padding-right: 5px;"> Auditor comunicaciones</td>
    </div>

    <div style="font-family: 'AvenirLTProMedium'; font-size: 15px;margin-top: 10px;">
        <td *ngIf="rol121"><img src="../../assets/img/ic_ambito.png"  mat-raised-button
            matTooltip="Auditor ambito"style="width: 30px; padding-right: 5px;"> Auditor ámbito</td>
    </div>

    
    <div style="font-family: 'AvenirLTProMedium'; font-size: 15px;margin-top: 10px;" >
        <td *ngIf="rol131"><img src="../../assets/img/ic_gobernabilidad.png"  mat-raised-button
            matTooltip="Gobernabilidad"style="width: 30px; padding-right: 5px;"> Auditor gobernabilidad</td>
       </div>

       
    <div style="font-family: 'AvenirLTProMedium'; font-size: 15px;margin-top: 10px;" >
        <td *ngIf="rol141"><img src="../../assets/img/ic_sustentabilidad.png"  mat-raised-button
            matTooltip="Sustentabilidad"style="width: 30px; padding-right: 5px;"> Auditor sustentabilidad</td>
    </div>

    <div style="font-family: 'AvenirLTProMedium'; font-size: 15px;margin-top: 10px;">
        <td *ngIf="rol151"><img src="../../assets/img/ic_coordinador.png"  mat-raised-button
            matTooltip="Coordinador"style="width: 30px; padding-right: 5px;"> Coordinador auditoria</td>
    </div>
      
        <div id="btnAtras"  class="atras" (click)="Atras()"  > </div> 
    </div>
  </div>

<div class="logo" style="background-color:#0B5077;padding-left: 10px; cursor:default;">
    <table >
        <tbody  >
        <tr >
        <td rowspan="2" width="50px" height="50px" style="background-image: url(../../../assets/img/circulo.png); background-size: 50px;"><a style="margin-left: 12px;font-family:'AvenirLTProBlack'; font-size: 20px;"  >{{iniciales }}</a></td>
        <td style="color: white;font-family: 'AvenirLTProBlack'; font-size: 18px;"><strong >&nbsp;{{nombreUsuario}}</strong></td>
        </tr>
        <tr>
            
            <td >
                <img *ngIf="rol1" src="../../assets/img/superusuariob.png" alt="error" class="hover" style="width: 28px; margin-left: 5px;" 
                data-toggle="tooltip" data-placement="bottom" title="Super usuario" >
                
                <img *ngIf="rol2" src="../../assets/img/administradorb.png" style="width: 28px; margin-left: 5px; "
                        data-toggle="tooltip" data-placement="bottom" title="Administrador">

                <img  *ngIf="rol3" src="../../assets/img/normab.png" alt="error"  style="width: 28px; margin-left: 5px;"
                data-toggle="tooltip" data-placement="bottom" title="Usuario norma" >

                <img *ngIf="rol4" src="../../assets/img/preguntasb.png" alt="error"  style="width: 28px; margin-left: 5px;"
                  data-toggle="tooltip" data-placement="bottom" title="Usuario preguntas">

                <img *ngIf="rol5" src="../../assets/img/sugerenciasb.png" alt="error"  style="width: 28px; margin-left: 5px;"
                data-toggle="tooltip" data-placement="bottom" title="Usuario sugerencias">

                <img *ngIf="rol6" src="../../assets/img/solucionesb.png" alt="error"  style="width: 28px; margin-left: 5px;"
                data-toggle="tooltip" data-placement="bottom" title="Usuario soluciones" >
                
                <img *ngIf="rol7" src="../../assets/img/auditorliderb.png" alt="error"  style="width: 28px; margin-left: 5px;"
                data-toggle="tooltip" data-placement="bottom" title="Auditor lider">

                <img *ngIf="rol8" src="../../assets/img/electricob.png" alt="error"  style="width: 28px; margin-left: 5px;"
                data-toggle="tooltip" data-placement="bottom" title="Auditor eléctrico">

                <img *ngIf="rol9" src="../../assets/img/climab.png" alt="error"  style="width: 28px; margin-left: 5px;"
                data-toggle="tooltip" data-placement="bottom" title="Auditor clima">

                <img *ngIf="rol10" src="../../assets/img/seguridadb.png" alt="error" style="width: 28px; margin-left: 5px;"
                data-toggle="tooltip" data-placement="bottom" title="Auditor seguridad">

                <img *ngIf="rol11" src="../../assets/img/comunicacionesb.png" alt="error"  style="width: 28px; margin-left: 5px;"
                data-toggle="tooltip" data-placement="bottom" title="Auditor comunicaciones">

                <img *ngIf="rol12" src="../../assets/img/ambitob.png" alt="error"  style="width: 28px; margin-left: 5px;"
                data-toggle="tooltip" data-placement="bottom" title="Auditor ambito">

                <img *ngIf="rol13" src="../../assets/img/gobernabilidadb.png" alt="error"  style="width: 28px; margin-left: 5px;"
                data-toggle="tooltip" data-placement="bottom" title="Auditor gobernabilidad">

                <img *ngIf="rol14" src="../../assets/img/sustentabilidadb.png" alt="error"  style="width: 28px; margin-left: 5px;"
                data-toggle="tooltip" data-placement="bottom" title="Auditor sustentabilidad">

                <img *ngIf="rol15" src="../../assets/img/coordinadorb.png" alt="error"  style="width: 28px; margin-left: 5px;"
                data-toggle="tooltip" data-placement="bottom" title="Coordinador auditoria">

                  <img *ngIf="more" type="button" src="../../assets/img/masdashboard.png" (click)="onClick()"  style="width: 15px;margin-left: 5px;">
                </td>
               
        </tr>

        </tbody>
        </table>
</div>
<div class="sidebar-wrapper" style="font-family:'AvenirLTProMedium';">
    
    <div *ngIf="isMobileMenu()">
        
        
      </div>
        <ul class="nav">
            <!---<li routerLinkActive="active" *ngFor="let menuItem of menuItems" class="{{menuItem.class}} nav-item"> --->
            <li routerLinkActive="active" *ngFor="let menuItem of menuItems" class="{{menuItem.class}} nav-item">
                <a class="nav-link" [routerLink]="[menuItem.path] " >
                    <i class="material-icons">{{menuItem.icon}}</i>
                    <p>{{menuItem.title}}</p>
                    <div *ngIf="circulo">
                        <p *ngIf="menuItem.title == 'NOTIFICACIONES'" >
                            <i style="margin-left:170px; margin-top: -38px;font-size: 12px;z-index: 10000; position:absolute; color:rgba(255, 255, 255, 1); ">{{notificacionesPendientes}}</i>
                            <i class="material-icons ncirculo"  style=" margin-left: 170px; margin-top: -38px; color:rgba(255, 0, 0, 0.5); z-index: 9000; position: absolute;">circle</i>  
                        </p>
                    </div>
                </a>
            </li>
        </ul>
    </div>

