import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InicioComponent } from './inicio/inicio.component';
import { OlvidocComponent } from './olvidoc/olvidoc.component';
import { CambiocComponent } from './cambioc/cambioc.component';
import { MensajeComponent } from './mensaje/mensaje.component';
import { RecuperarcComponent } from './recuperarc/recuperarc.component';
import { ProvisionalcComponent } from './provisionalc/provisionalc.component';
import { AuthGuard } from './guards/auth.guard';
import { RecuperacioncComponent } from './recuperacionc/recuperacionc.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';


const routes: Routes = [
  {path: 'inicio', component: InicioComponent},
  {path: 'olvidoc', component: OlvidocComponent},
  {path: 'cambioc', component: CambiocComponent, canActivate: [AuthGuard], data: { expectedRol: ["superUsuario","ADMINISTRADOR","USUARIO NORMA","USUARIO PREGUNTAS","USUARIO SUGERENCIAS","USUARIO SOLUCIONES","AUDITOR LIDER",
  "AUDITOR ELECTRICO","AUDITOR CLIMA","AUDITOR SEGURIDAD","AUDITOR COMUNICACIONES","AUDITOR AMBITO","AUDITOR GOBERNABILIDAD",
  "AUDITOR SUSTENTABILIDAD","COORDINADOR AUDITORIA"]}},
  {path: 'mensaje', component: MensajeComponent},
  {path: 'recuperarc', component: RecuperarcComponent},
  {path: 'provisionalc', component: ProvisionalcComponent},
  {path: 'recuperacionc', component: RecuperacioncComponent},
  {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
  {
    path: '',
    component: AdminLayoutComponent, canActivate: [AuthGuard], data: { expectedRol: ["superUsuario","ADMINISTRADOR","USUARIO NORMA","USUARIO PREGUNTAS","USUARIO SUGERENCIAS","USUARIO SOLUCIONES","AUDITOR LIDER",
    "AUDITOR ELECTRICO","AUDITOR CLIMA","AUDITOR SEGURIDAD","AUDITOR COMUNICACIONES","AUDITOR AMBITO","AUDITOR GOBERNABILIDAD",
    "AUDITOR SUSTENTABILIDAD","COORDINADOR AUDITORIA"]},
    children: [{
      path: '',
      loadChildren: () => import('src/app/layouts/admin-layout/admin-layout.module').then(m => m.AdminLayoutModule)
    }]
  }
 ];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
