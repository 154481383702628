
<div class="wrapper"  #container>


    <div class="sidebar" data-color="danger" data-background-color="white">
        <app-sidebar></app-sidebar>
        <div class="sidebar-background" style="background-image: url(./assets/img/sidebar-4.jpg)"></div>
    </div>


    <div class="main-panel" style="background-image: url(./assets/img/fondo1.png);background-size: cover;">

        <app-navbar></app-navbar>
        <router-outlet></router-outlet>
        
    </div>

</div>
