import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LoginServiceService } from '../login-service.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.scss']
})

export class InicioComponent implements OnInit {
  public contactForm: FormGroup;
  public active: boolean = false;
  public activeICREA: boolean = true;
  public activeFormulario: boolean = false;
  public advertencia: boolean;
  public errorc: boolean; // mensaje error contrasena
  public exitoso: boolean;
  public msj: string;
  private timeSplash=0;
  public userLog: string;
  public userId: string;

  hide = true;
  // tslint:disable-next-line: max-line-length
  private emailPattern: any = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
 
  constructor(private loginService: LoginServiceService,private nav:Router) {
      //verifica que el usuario ya ingreso
      if (localStorage.getItem("usuario") != null) {
        let user=localStorage.getItem("usuario");
        //envia el token para ser validado
        this.loginService.validarToken(user).subscribe (
          datos=>{
            //verifica que el token sea correcto
            if (datos['estado']=="Exitoso"){
                //Se debe implementar la rutina para redireccionamiento a la dashboard  
                this.nav.navigateByUrl('/dashboard');
            }else{
              localStorage.removeItem("usuario");
            }
          }
        );
      }
  }

  createForm() {
    return new FormGroup({
      email: new FormControl('', [Validators.required, Validators.minLength(5), Validators.pattern(this.emailPattern)]),
      password: new FormControl('', [Validators.required, Validators.minLength(5)]),
    });
  }


  onResetForm(): void {
    this.contactForm.reset();
  }

  login = {
    correo: null,
    pass: null
  }

  onClickMe() {
    this.advertencia = false;
    this.errorc = false;
    this.exitoso = false;
  }

  onSaveForm(): void { 
    //Activamos el loading
    this.active=true;
    if (this.contactForm.valid) {
      this.loginService.loginUsuario(this.login).subscribe (
        datos =>{
          //Desactivamos el loading
          this.active=false;
          switch (datos['estado']){
            case "Cuenta sin validar":
              this.alerta("importante",'Cuenta sin validar, por favor revise su correo electrónico y siga los pasos.');
              localStorage.removeItem("usuario");
              break;
            case "Exitoso":
              // acceso a la pantalla que corresponda segun el perfil de usuario
              this.grabarLocalStore(datos);
              let user =JSON.parse(localStorage.getItem("usuario"));
              let rol=user.rol;
              //valido si tiene asignado algun rol
              if(rol!=''){
                this.userLog="Inicio de sesión.";
                this.userId=datos["user"];
                this.almacenarLog();
                // se debe redireccionar a la dashboard
                this.nav.navigateByUrl('/dashboard');
              } else {
                this.alerta("importante",'Su usuario no tiene roles vigentes, contáctese con el administrador.');
                localStorage.removeItem("usuario");
              }
              break;
            case "Datos erróneos":
              this.alerta("error",'Datos incorrectos le quedan '+datos['mensaje']+' intentos');
              localStorage.removeItem("usuario");
              break;
            case "Bloqueado":
              this.alerta("error",'Usuario bloqueado por superar intentos fallidos.');
              this.grabarLocalStore(datos);
              //Verifica si el usuario acabo de ser bloqueado y registra el log
                this.userLog="Usuario bloqueado por superar intentos fallidos.";
                this.userId=datos['user'];
                this.almacenarLog();
                if (datos['mensaje']=="Supero intentos"){ //Notifica al superusuario unicamente cuando completa los intentos y evita que al seguir intentando se le envien mas notificaciones
                  this.loginService.enviarNotificacionSuperUsuarios({texto:this.userLog}).subscribe(
                    datos=>{
                      if (datos['mensaje']=="Exitoso"){
                      //Acciones requeridas
                      }
                    }
                  );
                }
                localStorage.removeItem("usuario");
              break;
            case "Recuperar":
              console.log("Se inicia el proceso de recuperación");
              if (datos["rol"]==""){
                this.alerta("importante","No puede continuar con la activación si no tiene roles vigentes");
                return;  
              }
              //Grabamos los datos provisionalmente
              this.grabarLocalStore(datos);
              this.userLog="Inicio de sesión.";
              this.userId=datos["user"];
              this.almacenarLog();
              this.nav.navigateByUrl('/cambioc');
              break;
            case "Desactivado":
              this.alerta("error","Su usuario ha sido inhabilitado. Tome contacto con el administrador.");
              localStorage.removeItem("usuario");
              break;
            case "El usuario no existe":
              this.alerta("error",'El usuario no existe');
              localStorage.removeItem("usuario");
              break;
            case "No se puede conectar a la base de datos":
              this.alerta("error",'No se puede conectar a la base de datos');
              localStorage.removeItem("usuario");
              break;
            case "Sesion activa":
              this.alerta("error",'El usuario ya tiene iniciada sesión en otro navegador o dispositivo');
              localStorage.removeItem("usuario");
              break;
            case "superusuario":
              this.alerta("error",'Contraseña incorrecta');
              localStorage.removeItem("usuario");
              break;
            default:
              this.alerta("importante",datos['estado']);
              localStorage.removeItem("usuario");
              break;
            }
        }
      );
    }
    else {
        //Desactivamos el loading
        this.active=false;
        this.alerta("importante","Campos incorrectos");
     } 
  }

  //Funcion que lo redirecciona a la pantalla olviddo contraseña
  mostrarOlvidoC(){
    this.nav.navigateByUrl('/olvidoc');
  }
  

  //Funcion que muestra los mensajes
  alerta(tipo:string,arg1: string) {
    this.msj = arg1;
    if(tipo == "importante"){
      this.advertencia = true;
    }
    if(tipo == "error"){
      this.errorc = true;
    }
    if(tipo == "exitoso"){
      this.exitoso = true;
    }
  }
  
  grabarLocalStore(usuarioInfo){
    let usuario={
      id:usuarioInfo["id"], //encriptado MD5 usado en guardarContra.php 
//      pass:usuarioInfo["pass"], //encriptado MD5
      usertoken:usuarioInfo["token"], //encriptado MD5 (correo encriptado)
      nombre:usuarioInfo["nombre"],
      apellido:usuarioInfo["apellido"],
      correo:usuarioInfo["correo"],
      user:usuarioInfo["user"],
      rol:usuarioInfo["rol"],
      newNot:usuarioInfo["newNot"],
      fecInicio:new Date
    }
    localStorage.setItem("usuario",JSON.stringify(usuario));
}

  get email() { return this.contactForm.get('email'); }
  get password() { return this.contactForm.get('password'); }

  ngOnInit(): void {
    this.contactForm = this.createForm();
    setInterval(()=>{
      if(this.timeSplash<5000){
        this.timeSplash+=500;
      } else {
        this.activeFormulario=true;
        this.activeICREA=false;
      }
    },500)

  }
  almacenarLog(){
    let datos={
      user:this.userId,
      descripcion:this.userLog
      //descripcion:"Agrego la norma "+ this.norma.numero + "."
    };
    //console.log(datos);
    //envia la informacion al servicio para insertar registro en el log
    this.loginService.insertarLog(datos).subscribe (
      datos=>{
        //verifica que el token sea correcto
        if (datos['estado']=="Exitoso"){
            //Se debe implementar la rutina para redireccionamiento a la dashboard  
        }
      }
    );
  }

}
