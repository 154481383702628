<html>
    <head>
        <title></title>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1">
        <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1" />
  
         <!-- Los iconos tipo Solid de Fontawesome-->
      <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.0.8/css/solid.css">
      <script src="https://use.fontawesome.com/releases/v5.0.7/js/all.js"></script>
    </head>

   <body style=" margin: 0 !important; padding: 0 !important;">

    <table border="0" cellpadding="0" cellspacing="0" width="100%">
   
        <!-- Tabla encabezado-->
          <tr>
            <td align="left">
                <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 800px;">
                    <tr>
                        <td align="center" valign="top" style="padding: 70px 20px 0px 20px;"> </td>
                    </tr>
                </table>
            </td>
        </tr>
        <!-- Fin tabla de encabezado-->

         <!-- Tabla formulario-->
  
         <tr>  <!--  encabezado-->
            <td  align="center" style="padding: 0px 20px 0px 20px;">
                <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width:800px;">
                    <tr>
    
                       <td class="opacity" bgcolor="#FFFFFF" align="center" valign="top" style="padding: 0px 20px 0px 20px; border-radius: 20px 20px 0px 0px; color: #2B405A; font-family:'AvenirLTProBlack';">
                        <div ><img src="../../assets/img/logoicrea.jpg" style="width: 25%; height: 25%; margin-top:25px;" alt=""> </div>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>  <!-- Fin encabezado-->

        <!-- cuerpo formulario-->
      <tr>
        <td  align="center" style="padding: 0px 20px 80px 20px;">
            <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 800px;">
                <tr>
                    <td class="opacity" bgcolor="#FFFFFF" align="center" style="padding: 10px 15px 0px 15px; color: #2B405A; font-family: 'AvenirLTProMediumOblique'; font-size: 18px;">
                     <!-- Validacion correo-->
                      <form class="col-12">
                        <div class="form-group" id="user-group">
                            <!-- Validaciones correo-->
                            <div ><img src="../../assets/img/fondo exito.png" style="width: 100%; height: 100%; margin-top:25px;" alt=""> </div>
                            
                        </div>
                        <!-- fin validacion correo-->
                      
                    </form>
        
                    </td>
                </tr>
                <tr>
                    <td class="opacity" bgcolor="#FFFFFF" align="center" style="padding: 20px 0px 8px 15px; border-radius: 0px 0px 20px 20px; color: #2B405A; font-family: 'AvenirLTProMedium'; font-size: 18px; font-weight: 400; line-height: 25px;">
                      <div class="col-12 olvidopas">
                         <a href="/mensaje"  style="color:#2B405A ;  font-family: 'AvenirLTProMedium'">Ir al sitio de Audit-ICREA</a>
                     </div>
                    </td>
                </tr>
            </table>

            <tr>
                <td  align="center" style="margin-top: 100px;">
                    <table border="0" cellpadding="0" cellspacing="0" width="100%" >
                        <tr>
                            <td align="center" style="color: #FFFF; font-family:'AvenirLTProMediumOblique'; font-size: 18px;"> <br>
                              <div>
                                  <img src="../../assets/img/derechosreservados.png " style = "width: 50%; height: 50%; margin-bottom: 0px;">
                              </div>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
        </td>
    </tr>

      <!-- Fin tabla formulario-->

      
        
  
  </table>
        
    </body>
</html>
