import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InicioComponent } from './inicio/inicio.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule} from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { OlvidocComponent } from './olvidoc/olvidoc.component';
import { CambiocComponent } from './cambioc/cambioc.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { LoginServiceService } from './login-service.service';
import { MensajeComponent } from './mensaje/mensaje.component';
import { RecuperarcComponent } from './recuperarc/recuperarc.component';
import { ProvisionalcComponent } from './provisionalc/provisionalc.component';
import { RecuperacioncComponent } from './recuperacionc/recuperacionc.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { DatePipe } from '@angular/common';
import { NgxMaskModule } from 'ngx-mask';
import { VerreportesComponent } from './verreportes/verreportes.component';
import { VersionComponent } from './version/version.component';




@NgModule({
  declarations: [
    AppComponent,
    InicioComponent,
    OlvidocComponent,
    CambiocComponent,
    MensajeComponent,
    RecuperarcComponent,
    ProvisionalcComponent,
    RecuperacioncComponent,
    AdminLayoutComponent,
    NavbarComponent,
    SidebarComponent,
    VerreportesComponent,
    VersionComponent

  ],
  imports: [
    ModalModule.forRoot(),
    NgxMaskModule.forRoot(),
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    MatInputModule,
    MatDialogModule,
    MatTableModule,
    MatFormFieldModule,
    MatButtonModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    PdfViewerModule,
  ],
  providers: [
    LoginServiceService,
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
