import { Component, OnInit } from '@angular/core';
import { NormaComponent } from 'src/app/norma/norma.component';
import { NavigationEnd, Router, RoutesRecognized, NavigationStart } from '@angular/router';
import { LoginServiceService } from 'src/app/login-service.service';



declare const $: any;
declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}

export const ROUTES: RouteInfo[] = [
  { path: '/dashboard', title: 'HOME',  icon: 'home', class: ' '},
  { path: '/user-profile', title: 'PERFIL',  icon: 'person', class: '' },
  { path: '/notifications', title: 'NOTIFICACIONES',  icon: 'notifications', class: 'noti' },
  { path: '/cerrar', title: 'CERRAR SESIÓN',  icon: 'unarchive', class: 'active-pro' }
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  nombre: string;
  apellido: string;
  nombreUsuario:string;
  rol:string;
  public iniciales;
  public capturaL;
  public notificacionesPendientes:string;
  private timeSplash=0;
  public circulo:boolean;

  aux: string;
  aux1: string;
  primera: string;
  menuItems: any[];
  mySubscription: any;
  contador:any=0;

  user:any;


   //Variables de control de los roles
   public rol1: boolean=false;
   public rol2: boolean=false;
   public rol3: boolean=false;
   public rol4: boolean=false;
   public rol5: boolean=false;
   public rol6: boolean=false;
   public rol7: boolean=false;
   public rol8: boolean=false;
   public rol9: boolean=false;
   public rol10: boolean=false;
   public rol11: boolean=false;
   public rol12: boolean=false;
   public rol13: boolean=false;
   public rol14: boolean=false;
   public rol15: boolean=false;
   public rol16: boolean=false;
   public active: boolean = false;
   public rol1111: boolean=false;
   public rol21: boolean=false;
   public rol31: boolean=false;
   public rol41: boolean=false;
   public rol51: boolean=false;
   public rol61: boolean=false;
   public rol71: boolean=false;
   public rol81: boolean=false;
   public rol91: boolean=false;
   public rol101: boolean=false;
   public rol111: boolean=false;
   public rol121: boolean=false;
   public rol131: boolean=false;
   public rol141: boolean=false;
   public rol151: boolean=false;
   public rol161: boolean=false;
   public more: boolean=false;


   onClick(){
     this.active =true;
   }

   Atras(){
     this.active =false;
   }

  
  constructor(private router: Router,private loginService: LoginServiceService) { 
    if (localStorage.getItem("usuario") == null) {
      this.router.navigateByUrl('/inicio');
    }
    this.circulo=true;
    this.user=JSON.parse(localStorage.getItem("usuario"));
    this.visualizacionRoles();
  }

  visualizacionRoles(){
    let rol=this.user.rol;
    this.rol1=false;
    this.rol2=false;
    this.rol3=false;
    this.rol4=false;
    this.rol5=false;
    this.rol6=false;
    this.rol7=false;
    this.rol8=false;
    this.rol9=false;
    this.rol10=false;
    this.rol11=false;
    this.rol12=false;
    this.rol13=false;
    this.rol14=false;
    this.rol15=false;
    this.rol16=false;
    //console.log(rol);
    while (rol.length>0){
      let coma=rol.indexOf(",");
      if (coma>0){
        let rolActual=rol.substring(0,coma);
        //console.log(rolActual+"\n");
        rol=rol.substring(coma+1,);
        this.activaIconosRoles(rolActual); 
      }else{
        //console.log(rol);
        this.activaIconosRoles(rol);
        break;
      }
    }  
  }

  activaIconosRoles(rolActual:string){
    //mostramos la ventana
    switch (rolActual){
      case this.loginService.roles[0]:
        this.rol1111=true;
        break;
      case this.loginService.roles[1]:
        this.rol21=true;
        break;
      case this.loginService.roles[2]:
        this.rol31=true;
        break;
      case this.loginService.roles[3]:
        this.rol41=true;
        break;
      case this.loginService.roles[4]:
        this.rol51=true;
        break;
      case this.loginService.roles[5]:
        this.rol61=true;
        break;
      case this.loginService.roles[6]:
        this.rol71=true;
        break;
      case this.loginService.roles[7]:
        this.rol81=true;
        break;
      case this.loginService.roles[8]:
        this.rol91=true;
        break;
      case this.loginService.roles[9]:
        this.rol101=true;
        break;
      case this.loginService.roles[10]:
        this.rol111=true;
        break;
      case this.loginService.roles[11]:
        this.rol121=true;
        break;
      case this.loginService.roles[12]:
        this.rol131=true;
        break;
      case this.loginService.roles[13]:
        this.rol141=true;
        break;
      case this.loginService.roles[14]:
        this.rol151=true;
        break;
      case this.loginService.roles[15]:
        this.rol161=true;
        break;
      default:
        break;
    }
    if(this.contador<5){
      switch (rolActual){
        case this.loginService.roles[0]:
          this.rol1=true;
          this.contador++;
          break;
        case this.loginService.roles[1]:
          this.rol2=true;
          this.contador++;
          break;
        case this.loginService.roles[2]:
          this.rol3=true;
          this.contador++;
          break;
        case this.loginService.roles[3]:
          this.rol4=true;
          this.contador++;
          break;
        case this.loginService.roles[4]:
          this.rol5=true;
          this.contador++;
          break;
        case this.loginService.roles[5]:
          this.rol6=true;
          this.contador++;
          break;
        case this.loginService.roles[6]:
          this.rol7=true;
          this.contador++;
          break;
        case this.loginService.roles[7]:
          this.rol8=true;
          this.contador++;
          break;
        case this.loginService.roles[8]:
          this.rol9=true;
          this.contador++;
          break;
        case this.loginService.roles[9]:
          this.rol10=true;
          this.contador++;
          break;
        case this.loginService.roles[10]:
          this.rol11=true;
          this.contador++;
          break;
        case this.loginService.roles[11]:
          this.rol12=true;
          this.contador++;
          break;
        case this.loginService.roles[12]:
          this.rol13=true;
          this.contador++;
          break;
        case this.loginService.roles[13]:
          this.rol14=true;
          this.contador++;
          break;
        case this.loginService.roles[14]:
          this.rol15=true;
          this.contador++;
          break;
        case this.loginService.roles[15]:
          this.rol16=true;
          this.contador++;
          break;
        default:
          break;
      }
    } else {
      this.more=true;
    }
  }



  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.mySubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.router.navigated = true;
        setInterval(()=>{
          if(this.timeSplash<150){
            this.timeSplash+=100;
          } else {
            this.router.navigated = false;
          }
        },100);
      }
    });
    
    if (localStorage.getItem("usuario") != null) {
      //recuperamos el id del usuario
      let user=JSON.parse(localStorage.getItem("usuario"));
      this.nombre=user.nombre;
      this.apellido=user.apellido;
      this.rol=user.rol;
      //Evaluamos si el usuario tiene mas de 9 notificaciones

       // --------------------- Agregar parametro rol-----------------//
      let superU=false; 
      if (user["rol"].indexOf(this.loginService.roles[0])>=0 ){
        superU=true;
        user.newNot=0;
        }else{
          if(user.newNot>9){
            this.notificacionesPendientes=`9+`;
          }else {
            this.notificacionesPendientes=user.newNot;
          }
        
        }
      //verificamos si no tiene notificaciones
      if(user.newNot<1){
        this.circulo=false;
      }
    }
    this.calcularInicial();
    this.calcularNombreUsuario();
  }
  
  isMobileMenu() {
    if ($(window).width() > 991) {
        return false;
    }
    return true;
}

calcularNombreUsuario() {
  this.aux='';
  for (let x = 0; x < this.nombre.length; x++){
    this.primera = this.nombre.charAt(x);
    if (this.primera === ' ' ){
        x=100;
    }else {
      this.aux = this.aux + this.nombre.charAt(x);
    }
    
  }

  //Capturamos el primer apellido
  this.aux1='';
  for (let x = 0; x < this.apellido.length; x++){
    this.primera = this.apellido.charAt(x);
    if (this.primera === ' ' ){
        x=100;
    }else {
      this.aux1 = this.aux1 + this.apellido.charAt(x);
    }
  }

  this.nombreUsuario = this.aux+" "+this.aux1;
}

// Funcion que muestra las iniciales
calcularInicial(){
  this.iniciales =  this.nombre.charAt(0) + this.apellido.charAt(0);
  this.iniciales = this.iniciales.toUpperCase();
}

}
