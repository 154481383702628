import { Component, HostListener, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import swal from 'sweetalert2';
import {LoginServiceService} from '../login-service.service';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/timer';


@Component({
  selector: 'app-olvidoc',
  templateUrl: './olvidoc.component.html',
  styleUrls: ['./olvidoc.component.css']
})
export class OlvidocComponent implements OnInit {

   //----------- Listener del movimiento del mouse ------------
 @HostListener('document:mousemove', ['$event']) 
 onMouseMove(e) {
   if (this.timerCumplido){
     this.onClickMe();
   }
   //console.log(e);
 }
 timerCumplido=false;
 private subscription: Subscription;
 private timer: Observable<any>;

 
  public contactForm: FormGroup;
  public active: boolean = false;
  public advertencia: boolean;
  public errorc: boolean; // mensaje error contrasena
  public exitoso: boolean;
  public msj: string;
  
  hide = true;
  usuario={
    correo:null
  }
  // tslint:disable-next-line: max-line-length
  private emailPattern: any = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  constructor(private loginService: LoginServiceService) { }

  createForm() {
    return new FormGroup({
      email: new FormControl('', [Validators.required, Validators.minLength(5), Validators.pattern(this.emailPattern)]),
    });
  }

  onClickMe() {
    this.advertencia = false;
    this.errorc = false;
    this.exitoso = false;
  }

  onResetForm(): void {
    this.contactForm.reset();
  }

  onSaveForm(): void {
    this.active=true;
    if (this.contactForm.valid) {
      this.loginService.olvidoContra(this.usuario).subscribe (
        datos =>{
          this.active = false;
          switch (datos['estado']){
            case "Exitoso":
              // acceso a la pantalla que corresponda segun el perfil de usuario
              this.alertExitoso("Se ha enviado un correo con instrucciones para recuperación de contraseña");
              this.almacenarLog();
              this.onResetForm();
              break;
            default:
              this.alerta('importante', datos['estado']);  
              break;
          }
        }
      );
    }else{
      this.active =false;
      this.alerta("importante","Campo incorrecto.");
    }
  }

  
  get email() { return this.contactForm.get('email'); }

  ngOnInit(): void {
  this.contactForm = this.createForm();
  }

  //Funcion que muestra los mensajes
  alerta(tipo:string,arg1: string) {
    this.msj = arg1;
    if(tipo == "importante"){
      this.advertencia = true;
    }
    if(tipo == "error"){
      this.errorc = true;
    }
    if(tipo == "exitoso"){
      this.exitoso = true;
    }
  }
  almacenarLog(){
    //Verifica que la informacion este disponible en el LocalStore
    if (localStorage.getItem("usuario") != null) {
      let user=JSON.parse(localStorage.getItem("usuario"));
      let datos={
        user:user["user"],
        descripcion:"Solicito contraseña temporal."
        //descripcion:"Agrego la norma "+ this.norma.numero + "."
      };
      //console.log(datos);
      //envia la informacion al servicio para insertar registro en el log
      this.loginService.insertarLog(datos).subscribe (
        datos=>{
          //verifica que el token sea correcto
          if (datos['estado']=="Exitoso"){
              //Se debe implementar la rutina para redireccionamiento a la dashboard  
          }
        }
      );
    }
  }
  public alertExitoso(msj: string){
    //Lo que se desa mostrar (alert exitoso)
    this.exitoso=true;
    let tiempo=2000;
    this.msj=msj;
    this.timer=Observable.timer(tiempo); // en milisegundos
    this.subscription = this.timer.subscribe(() => {
        // set showloader to false to hide loading div from view after 5 seconds
        this.timerCumplido = true;
    });
   }
} 
