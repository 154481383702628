<html>

    <head>
        <title></title>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1">
      <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1" />

       <!-- Los iconos tipo Solid de Fontawesome-->
    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.0.8/css/solid.css">
    <script src="https://use.fontawesome.com/releases/v5.0.7/js/all.js"></script>
          
            
    </head>
    
     <!-- Tabla para backg -->
     <body style=" margin: 0 !important; padding: 0 !important;">

       <!-- Mensaje cerrar sesion -->
       <div *ngIf="cerrarSesion"class="contenedoradvertencia"></div>
       <div *ngIf="cerrarSesion"class="contenedoradvertencia1">
         <div class="contenedorMensaje"> 
           <div class ="encabezadoalarma"><img src="assets/img/en_warningp.png" width=375px; alt="warning">  </div>
           <div class="cuerpoalarma" style="font-family:'AvenirLTProMedium'; font-size: 25px; text-align: center; line-height: 120%;"> <p>¿Está seguro que quiere cerrar sesión?</p>
             <p style="padding: 10px;">
               <button class="botonsi" type="button" (click)="confirmar()" style="font-family: 'AvenirLTProBlack';font-size:18px; color: white;">SI</button> 
               <button class="botonno" type="button" (click)="onClickMe()"style="font-family: 'AvenirLTProBlack';font-size:18px; color: white;" >NO</button>
             </p>
             </div>
         </div>
       </div>
       <!-- Fin mensaje cerrar sesion -->

       <!-- Gif Loading-->
      <div *ngIf="active"class="contenedorLoading"></div>
      <div *ngIf="active"class="contenedorLoading1">
        <div class="loading"><img src="assets/img/loading.gif" width="200px" alt="splash" ></div>
      </div>

      <!-- Mensaje advertencia -->
      <div *ngIf="advertencia"class="contenedoradvertencia"></div>
      <div *ngIf="advertencia"class="contenedoradvertencia1">
        <div class="contenedorMensaje"> 
          <div class="btonalarma"><img type="button" (click)="onClickMe()" src="assets/img/btn_warning.png" width= 45px; alt=""></div>
          <div class ="encabezadoalarma"><img src="assets/img/en_warningp.png" width=375px; alt="warning">  </div>
          <div class="cuerpoalarma" style="font-family:'AvenirLTProMedium'; font-size: 25px; text-align: center;"> <p>{{msj}}</p></div>
        </div>
      </div>
      <!-- Fin mensaje advertencia -->

      <!-- Mensaje Error contrasena-->
      <div *ngIf="errorc"class="contenedoradvertencia"></div>
      <div *ngIf="errorc"class="contenedoradvertencia1">
        <div class="contenedorMensaje"> 
          <div class="btonalarma"><img type="button" (click)="onClickMe()" src="assets/img/btn_error.png" width= 45px; alt=""></div>
          <div class ="encabezadoalarma"><img src="assets/img/en_errorp.png" width=375px; alt="error">  </div>
          <div class="cuerpoalarma" style="font-family:'AvenirLTProMedium'; font-size: 25px;text-align: center;" > <p>{{msj}}</p></div>
        </div>
      </div>
      <!-- Fin mensaje Error contrasena-->

        <!-- Mensaje Exitoso -->
        <div *ngIf="exitoso"class="contenedoradvertencia"></div>
        <div *ngIf="exitoso"class="contenedoradvertencia1">
          <div class="contenedorMensaje"> 
            <div class="btonalarma"><img type="button" (click)="onClickMe()" src="assets/img/btn_exitoso.png" width= 45px; alt=""></div>
            <div class ="encabezadoalarma"><img src="assets/img/exitosod.png" width=375px; alt="error">  </div>
            <div class="cuerpoalarma" style="font-family:'AvenirLTProMedium'; font-size: 25px;text-align: center;" > <p>{{msj}}</p></div>
          </div>
        </div>
        <!-- Fin mensaje Exitoso-->



        <!-- HIDDEN PREHEADER TEXT -->
        
        <table border="0" cellpadding="0" cellspacing="0" width="100%">
     
          <!-- Tabla encabezado-->
            <tr>
              <td align="left">
                <div class="figure">
                  <img src="assets/img/icrealog.png" width="200"alt="logoicrea">
                </div>
                
                  <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 375px;">
                      <tr>
                          <td align="center" valign="top" style="padding: 50px 20px 0px 20px;"> </td>
                      </tr>
                  </table>
              </td>
          </tr>
          <!-- Fin tabla de encabezado-->
           <!-- Tabla formulario-->
    
          <tr>  <!--  encabezado-->
            <td  align="center" style="padding: 0px 20px 0px 20px;">
                <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 375px;">
                    <tr>
    
                       <td class="opacity" bgcolor="#D1D5DB" align="center" valign="top" style="padding: 0px 20px 0px 20px; border-radius: 20px 20px 0px 0px; color: #2B405A;"> 
                        <div style="margin-top:25px; margin-bottom: 10px;"> 
                        <h1 style="color: #2B405A; font-family:'AvenirLTProBlack';font-size: 25px;">CAMBIAR CONTRASEÑA</h1> </div>
                        <div  style="color:#2B405A; margin-top:0px; margin-bottom: 5px;padding: 0px 0px 0px 0px; font-family:'AvenirLTProMedium';font-size:18px;line-height: 110% "> 
                          Por favor ingrese su nueva contraseña para acceder. </div>
                        </td>

                       
                    </tr>
                </table>
            </td>
        </tr>  <!-- Fin encabezado-->
    
        <!-- cuerpo formulario-->
        <tr>
          <td  align="center" style="padding: 0px 20px 1.4% 20px;">
              <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 375px;">
                  <tr>
                    <td class="opacity" bgcolor="#D1D5DB" align="center" style="padding: 5px 15px 0px 15px; color: #2B405A; font-family: 'AvenirLTProMediumOblique'; font-size: 15px;">
                       <!-- Validacion contrasena-->
                        <form [formGroup]="contactForm" (ngSubmit)="onSaveForm()" class="col-12">
                         <!-- Contrasena-->
                        <div class="form-group" id="password-group">
                            <input matInput type="password" class="form-control" placeholder="Contraseña" [(ngModel)]="contra.pass1" [type]= "hideU ? 'password': 'text' "formControlName="password" >
                            <i style="cursor:pointer; position: absolute;" class= "material-icons" matSuffix (click)="hideU = !hideU"  >
                              {{hideU ? 'visibility':'visibility_off'}}    
                              </i>
                            <div class="alert-danger" *ngIf="password.invalid && (password.dirty || password.touched)">
                              <div class="container-error-message" *ngIf="password.errors.required">
                                La contraseña es requerida
                              </div>
                              <div class="container-error-message" *ngIf="password.errors.minlength">
                                debe tener al menos 5 caracteres
                              </div>
                            </div>
              
                            
                          </div>
    
                          <!-- Fin validacion Contrasena-->

                           <!-- Contrasena-->
                        <div class="form-group" id="password-group">
                          <input matInput type="password" class="form-control" placeholder="Confirmar contraseña" [(ngModel)]="contra.pass2" [type]= "hide ? 'password': 'text' "formControlName="contrasena" name="password" >
                          <i style="cursor:pointer; position: absolute;" class= "material-icons" matSuffix (click)="hide = !hide"  >
                            {{hide ? 'visibility':'visibility_off'}}    
                            </i>
                          <div class="alert-danger" *ngIf="contrasena.invalid && (contrasena.dirty || contrasena.touched)">
                            <div class="container-error-message" *ngIf="contrasena.errors.required">
                              La contraseña es requerida
                            </div>
                            <div class="container-error-message" *ngIf="contrasena.errors.minlength">
                              debe tener al menos 5 caracteres
                            </div>
                          </div>               
                        </div>

                          <button type="submit" class="btn btn-dark"style="align-content:center; height:40px; width:310px; border-radius:50px; font-family: 'AvenirLTProBlack';font-size: 16px;">CONTINUAR</button>
                          
                      </form>
                      
                      </td>
                  </tr>
                  <tr>
                    <td class="opacity" bgcolor="#D1D5DB" align="right" style="padding: 0px 0px 5px 15px; font-family: 'AvenirLTProBlack'; font-size: 14px; ">
                      <div class="col-12 cancelarc">
                        <a type="button" (click)="mostrarCerrarSesion()" style="color:#0B5077; text-align: right;"> <b>Cancelar</b></a>
                      </div>
                    </td>
                </tr>
                
                <tr>
                  <td class="opacity" bgcolor="#D1D5DB" align="center" style="padding: 0px 0px 0px 0px; border-radius: 0px 0px 20px 20px; color:#0B5077; font-family: 'AvenirLTProMedium'; font-size: 16px; line-height: 120%; ">
                      <div class="col-12 mensajes">
                       <p>Si necesita ayuda puede contactarnos a<br> info@audit-icrea.com</p>
                      </div>
                    </td>
                </tr>
              </table>
          </td>
      </tr>
    
        <!--fin cuerpo formulario-->
        <!-- Fin tabla formulario-->
    
 <!--Insignia-->
  
 <tr>
  <td  align="right" style="padding: 0px 30px 0px 20px;">
    <table border="0" cellpadding="0" cellspacing="0" width="100%" >
      <tr>
          <td class="insignia" align="right" style="font-family:'AvenirLTProMediumOblique'; font-size: 18px;"> <br>
            <h1 style="font-family:'AvenirLTProMediumOblique';">
                ¡Nos reinventamos! Somos una empresa libre de papel, amigable con el medio ambiente.<br/>
                Nuestros procesos ahora son digitales manteniendo nuestros altos estándares de calidad,<br/>
                confiabilidad y compromiso con nuestros clientes y empleados.<br/>
                Audit-ICREA siempre contigo.
            </h1> 
          </td>
      </tr>
    </table>
  </td>
</tr>
<!-- Fin insignia--->
    
    </table>
  
    </body>
    
    </html>
    
