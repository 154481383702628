import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginServiceService } from '../login-service.service';

@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.css']
})
export class VersionComponent implements OnInit {

  constructor(private loginService: LoginServiceService, private router:Router) { }

  active: boolean = false;
  activeInicial:boolean = true;
  disGuardar:boolean = true;
  disModificar:boolean = false;
  changed:boolean = false;
  public advertencia: boolean;
  public errorc: boolean; // mensaje error contrasena
  public exitoso: boolean;
  public msj: string;
  public contactForm: FormGroup;
  search:string = ' ';

  ngOnInit(): void {
    this.contactForm = this.createForm();
    
    this.loginService.consultarVigenciaNorma().subscribe(
      datos=>{
        if (datos['mensaje']=="Exitoso"){
          this.search=datos['vigencia'];
          this.contactForm.controls['numero_norma'].disable();
        }
        else {
          this.search='';
        }
      },
      error=>{
        this.search='';
      }
    );
  }

  
  createForm() {
    return new FormGroup({
      numero_norma: new FormControl('', [Validators.required]),
    });
  }

  
  busChange(){
  }

  agregarAtras(){
    //Redireccionamos a la pagina de modificar norma 
    this.router.navigateByUrl('/norma');
  }

  modificar(){
    this.contactForm.controls['numero_norma'].enable();
    this.disGuardar = false;
    this.disModificar = true;
  }

  guardar(){
    this.disGuardar=true;
    this.active=true;
    this.loginService.cambiarVigenciaNorma(this.search).subscribe(
      datos=>{
        if (datos['mensaje']=="Exitoso"){
          this.msj="El número de vigencia de la norma ha sido actualizado exitosamente"
          this.exitoso = true;
          this.active = false;
          this.disGuardar=false;
        } else {
          this.msj="Por favor intente más tarde."
          this.errorc = true;
          this.active = false;
          this.disGuardar=false;
        }
      },
      error=>{
        this.msj="Por favor intente más tarde."
        this.errorc = true;
        this.active = false;
        this.disGuardar=false;
      }
    );
  }


  onClickMe() {
    this.advertencia = false;
    this.errorc = false;
    this.exitoso = false;
    if (this.msj=="El número de vigencia de la norma ha sido actualizado exitosamente"){
      window.location.reload();
    }
  }
}
