import { Component, OnInit, ElementRef } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ROUTES } from '../sidebar/sidebar.component';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  private listTitles: any[];
  location: Location;
  mobile_menu_visible: any = 0;
  private toggleButton: any;
  private sidebarVisible: boolean;

  constructor(location: Location, private element: ElementRef,private router:Router) { 
    this.location = location;
    this.sidebarVisible = false;
  }

  ngOnInit(){
    this.listTitles = ROUTES.filter(listTitle => listTitle);
    const navbar: HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
    this.router.events.subscribe((event) => {
      this.sidebarClose();
       var $layer: any = document.getElementsByClassName('close-layer')[0];
       if ($layer) {
         $layer.remove();
         this.mobile_menu_visible = 0;
       }
   });
  }

  sidebarOpen() {
    const toggleButton = this.toggleButton;
    const body = document.getElementsByTagName('body')[0];
    setTimeout(function(){
        toggleButton.classList.add('toggled');
    }, 500);

    body.classList.add('nav-open');

    this.sidebarVisible = true;
};

sidebarClose() {
  const body = document.getElementsByTagName('body')[0];
  this.toggleButton.classList.remove('toggled');
  this.sidebarVisible = false;
  body.classList.remove('nav-open');
};

sidebarToggle() {
  // const toggleButton = this.toggleButton;
  // const body = document.getElementsByTagName('body')[0];
  var $toggle = document.getElementsByClassName('navbar-toggler')[0];

  if (this.sidebarVisible === false) {
      this.sidebarOpen();
  } else {
      this.sidebarClose();
  }
  const body = document.getElementsByTagName('body')[0];

  if (this.mobile_menu_visible == 1) {
      // $('html').removeClass('nav-open');
      body.classList.remove('nav-open');
      if ($layer) {
          $layer.remove();
      }
      setTimeout(function() {
          $toggle.classList.remove('toggled');
      }, 400);

      this.mobile_menu_visible = 0;
  } else {
      setTimeout(function() {
          $toggle.classList.add('toggled');
      }, 430);

      var $layer = document.createElement('div');
      $layer.setAttribute('class', 'close-layer');


      if (body.querySelectorAll('.main-panel')) {
          document.getElementsByClassName('main-panel')[0].appendChild($layer);
      }else if (body.classList.contains('off-canvas-sidebar')) {
          document.getElementsByClassName('wrapper-full-page')[0].appendChild($layer);
      }

      setTimeout(function() {
          $layer.classList.add('visible');
      }, 100);

      $layer.onclick = function() { //asign a function
        body.classList.remove('nav-open');
        this.mobile_menu_visible = 0;
        $layer.classList.remove('visible');
        setTimeout(function() {
            $layer.remove();
            $toggle.classList.remove('toggled');
        }, 400);
      }.bind(this);

      body.classList.add('nav-open');
      this.mobile_menu_visible = 1;

  }
};

getTitle(){
  var titlee = this.location.prepareExternalUrl(this.location.path());
  if(titlee.charAt(0) === '#'){
      titlee = titlee.slice( 1 );
  }

  for(var item = 0; item < this.listTitles.length; item++){
      if(this.listTitles[item].path === titlee){
          return this.listTitles[item].title;
      }
  }

  let prueba = String(this.location.path());

  switch (prueba){
    case '/norma': {
      return 'NORMA';
      break;
   }

   case '/agregarnorma': {
    return 'NORMA/AGREGAR';
    break;
 }

 case '/niveluno': {
  return 'NORMA/AGREGAR/NIVEL 1';
  break;
}

case '/niveldos': {
  return 'NORMA/AGREGAR/NIVEL 2';
  break;
}

case '/niveltres': {
  return 'NORMA/AGREGAR/NIVEL 3';
  break;
}

case '/nivelcuatro': {
  return 'NORMA/AGREGAR/NIVEL 4';
  break;
}

case '/nivelcinco': {
  return 'NORMA/AGREGAR/NIVEL 5';
  break;
}

case '/modificarnorma': {
  return 'NORMA/MODIFICAR';
  break;
}

case '/modniveluno': {
  return 'NORMA/MODIFICAR/NIVEL 1';
  break;
}

case '/modniveldos': {
  return 'NORMA/MODIFICAR/NIVEL 2';
  break;
}

case '/modniveltres': {
  return 'NORMA/MODIFICAR/NIVEL 3';
  break;
}

case '/modnivelcuatro': {
  return 'NORMA/MODIFICAR/NIVEL 4';
  break;
}

case '/modnivelcinco': {
  return 'NORMA/MODIFICAR/NIVEL 5';
  break;
}

case '/consulta': {
  return 'NORMA/CONSULTAR';
  break;
}

case '/eliminar': {
  return 'NORMA/ELIMINAR';
  break;
}

case '/version': {
  return 'NORMA/VERSIÓN';
  break;
}

case '/usuarios': {
  return 'USUARIOS';
  break;
}

case '/agregarusuarios': {
  return 'USUARIOS/AGREGAR';
  break;
}


case '/modificarusuarios': {
  return 'USUARIOS/MODIFICAR';
  break;
}

case '/cedulas': {
  return 'CÉDULAS';
  break;
}

case '/revision': {
  return 'CÉDULAS/PUNTOS DE REVISIÓN';
  break;
}

case '/sugerencias': {
  return 'CÉDULAS/SUGERENCIAS';
  break;
}

case '/soluciones': {
  return 'CÉDULAS/SOLUCIONES';
  break;
}

case '/agregarpuntos': {
  return 'CÉDULAS/PUNTOS DE REVISIÓN/AGREGAR';
  break;
}

case '/modificarpuntos': {
  return 'CÉDULAS/PUNTOS DE REVISIÓN/MODIFICAR';
  break;
}

case '/consultarsugerencia': {
  return 'CÉDULAS/SUGERENCIAS/CONSULTAR';
  break;
}

case '/consultarsoluciones': {
  return 'CÉDULAS/SOLUCIONES/CONSULTAR';
  break;
}

case '/agregarauditoria': {
  return 'AUDITORIAS/AGREGAR';
  break;
}

case '/consultarauditoria': {
  return 'AUDITORIAS';
  break;
}

case '/modificarauditoria': {
  return 'AUDITORIAS/MODIFICAR';
  break;
}

case '/preguntasauditoria': {
  return 'AUDITORIAS/PREGUNTAS';
  break;
}
case '/consultarReportes': {
  return 'REPORTES';
  break;
}

case '/reportes': {
  return 'REPORTES';
  break;
}

case '/informes': {
  return 'REPORTES/INFORMES';
  break;
}

case '/vigilancia': {
  return 'VIGILANCIA';
  break;
}

case '/verReportes': {
  let especialidad = localStorage.getItem("especialidadReporte");
  return 'REPORTES/'+especialidad;
  break;
}

   default: {
    return 'ICREA';
    break;
 }

  }


}

}
