import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import { Observable } from 'rxjs';
import { LoginServiceService } from '../login-service.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private loginService: LoginServiceService, private router:Router){
    //verifica que el usuario ya ingreso

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let user=localStorage.getItem("usuario");
    //los roles permitidos
    const expectedRol = route.data.expectedRol;
    var cont=0;

    //variable de control
    var control =0;
    var userRol: string[]=[]
    //Validamos si el usuario inicio sesion
    if (user !=null){
       //datos del usuario
        var jsonobject = JSON.parse(user);
        let rol = jsonobject.rol;

      //Se separan los roles del usuario y se almacenan en un arreglo
      while (rol.length>0){
        let coma=rol.indexOf(",");
        if (coma>0){
          let rolActual=rol.substring(0,coma);
          //console.log(rolActual+"\n");
          rol=rol.substring(coma+1,);
          userRol.push(rolActual); 
        }else{
          //console.log(rol);
          userRol.push(rol);
          break;
        }
      }  
      

      //recorremos el arreglo
      for (cont=0;cont<userRol.length;++cont){
        for (let i in expectedRol){
          let auxRol = expectedRol[i];
          if(auxRol === userRol[cont]){
            control =1;
          }
        }
      }

      //validamos la info
      if(control == 1){
        return true;
      } else {
        //tener en cuenta para redireccionara a la dashboard
        if (rol==""){
          this.router.navigateByUrl('/inicio');
          this.logout(jsonobject);
          localStorage.removeItem("usuario");
          return false;
        }
      }
    } else {
      this.router.navigateByUrl('/inicio');
      return false;
    }
    
  }

logout(user){
  let datos={
    user:user["user"]
  };
  //envia la informacion al servicio para insertar registro en el log
  this.loginService.logoutUsuario(datos).subscribe (
    datos=>{
      if (datos['estado']=="Exitoso"){
          //accion de ser necesaria
      }
    }
  );
}

  
}
