import { Injectable } from '@angular/core';

import { HttpClient} from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class LoginServiceService {

  //Creamos los nombres de variables que vamos a acceder en cada nivel
  nombreNivel1:string;
  nombreNivel2:string;
  nombreNivel3:string;
  nombreNivel4:string;
  nombreNivel5: string;
  numNivel1:string;
  numNivel2:string;
  numNivel3:string;
  numNivel4:string;
  numNivel5: string;
// valores de la pantalla cedulas modificar
  tempPuntoRevision=null;
  tempSugerencia=null;
  tempSolucion=null;
  vartemp=null;
  //descripcion:string= '¿De qué  color es el balón blanco de futbol?';
  //severidad:string= '1 - Rojo';
  //sensibilidad: string='50';
  //predecesorasText: string='800,1080,250';

  public articuloMostrar=['Artículo prueba'];
  public numArtMostrar=['410.1.5'];


  TIEMPO_INACTIVIDAD=1800;
  estadoProcesoInactividad=false;
  
  roles=["superUsuario","ADMINISTRADOR","USUARIO NORMA","USUARIO PREGUNTAS","USUARIO SUGERENCIAS","USUARIO SOLUCIONES","AUDITOR LIDER",
          "AUDITOR ELECTRICO","AUDITOR CLIMA","AUDITOR SEGURIDAD","AUDITOR COMUNICACIONES","AUDITOR AMBITO","AUDITOR GOBERNABILIDAD",
          "AUDITOR SUSTENTABILIDAD","COORDINADOR AUDITORIA"];

  public rolesTextoMostrar= ['SuperUsuario','Administrador','Usuario norma','Usuario de preguntas','Usuario de sugerencias','Usuario de soluciones','Auditor líder',
                             'Auditor eléctrico','Auditor de clima','Auditor de seguridad','Auditor de comunicaciones','Auditor de ámbito','Auditor de gobernabilidad',
                             'Auditor de sustentabilidad','Coordinador de auditorías'];
  
  //URL = "https://audit-icrea.com/webservices/consultarUsuarios.php";
  URL = "https://audit-icrea.com/webservices/";

  //se crea la variable del identificador de usuarios
  usuarioTemp=null;
  constructor(private http: HttpClient) { }
  
  loginUsuario(login){
    return this.http.post(`${this.URL}consultarUsuarios.php`,JSON.stringify(login));
  }
  
  logoutUsuario(user){
    return this.http.post(`${this.URL}logoutUsuario.php`,JSON.stringify(user));
  }

  modificarUsuario(datos) {
    return this.http.post(`${this.URL}modificarUsuario.php`,JSON.stringify(datos));
  }

  usuarioInfo(user) {
    return this.http.post(`${this.URL}usuarioInfo.php`,JSON.stringify(user));
  }

  validarToken(token){
    return this.http.post(`${this.URL}validarToken.php`,token);
  }

  guardarContra(user){
    return this.http.post(`${this.URL}guardarContra.php`,JSON.stringify(user));
  }

  olvidoContra(correo){
    return this.http.post(`${this.URL}olvidoContra.php`,JSON.stringify(correo));
  }

  validarNorma(norma){
    return this.http.post(`${this.URL}listarNorma.php`,JSON.stringify(norma));
  }

  insertarNorma(norma){
    return this.http.post(`${this.URL}insertarNorma.php`,JSON.stringify(norma));
  }

  subirArchivo(archivo) {
    return this.http.post(`${this.URL}subirArchivo.php`, JSON.stringify(archivo));
  }

  verificarPdf(archivo) {
    return this.http.post(`${this.URL}verificacionPDF.php`, JSON.stringify(archivo));
  }

  modificarNorma(norma) {
    return this.http.post(`${this.URL}modificarNorma.php`, JSON.stringify(norma));
  }

  buscarCriterio(texto) {
    return this.http.post(`${this.URL}busqueda.php`, JSON.stringify(texto));
  }

  eliminarNorma(norma) {
    return this.http.post(`${this.URL}eliminarNorma.php`, JSON.stringify(norma));
  }

  eliminarUsuario(user) {
    return this.http.post(`${this.URL}eliminarUsuario.php`, JSON.stringify(user));
  }

  busquedaUsuarios(buscarCriterio) {
    let usuario=JSON.parse(localStorage.getItem("usuario"));
    //console.log("Notificacion cambio de norma");
    let criterio={
      parametro:buscarCriterio.parametro,
      roles:usuario.rol
    }
    return this.http.post(`${this.URL}busquedaUsuarios.php`, JSON.stringify(criterio));
  }

  desbloquearUsuario(usuario) {
    return this.http.post(`${this.URL}desbloquearUsuario.php`, JSON.stringify(usuario));
  }

  buscarUsuarioXRol(rol) {
    return this.http.post(`${this.URL}consultarUsuariosxRol.php`, JSON.stringify(rol));
  }

  agregarAuditoria(auditoria) {
    return this.http.post(`${this.URL}crearAuditoria.php`, JSON.stringify(auditoria));
  }

  consultarAuditoria(auditoria) {
    return this.http.post(`${this.URL}consultarAuditorias.php`, JSON.stringify(auditoria));
  }

  consultarAuditoriaAuditor(consulta) {
    return this.http.post(`${this.URL}consultarAuditoriasAuditor.php`, JSON.stringify(consulta));
  }

  modificarAuditoria(auditoria) {
    return this.http.post(`${this.URL}modificarAuditoria.php`, JSON.stringify(auditoria));
  }

  modificarPreguntasIniciales(auditoria) {
    return this.http.post(`${this.URL}modificarPreguntasIniciales.php`, JSON.stringify(auditoria));
  }

  setImage(imagen: File, path){
    const formData: FormData = new FormData();
    let name=path + imagen.name;
    formData.append('logo', imagen ,name);
    formData.append('path', path);
    console.log(name);
    return this.http.post(`${this.URL}subirImagen.php`,formData);
  }

  buscarPuntoRevision(criterio) {
    return this.http.post(`${this.URL}consultaPuntosRevision.php`, JSON.stringify(criterio));
  }

  crearPuntoRevision(punto) {
    return this.http.post(`${this.URL}crearPuntoRevision.php`, JSON.stringify(punto));
  }

  puntoRevisionOnOff(punto) {
    return this.http.post(`${this.URL}PuntoRevisionOnOff.php`, JSON.stringify(punto));
  }

  consultaSolucion(solucion) {
    return this.http.post(`${this.URL}consultaSoluciones.php`, JSON.stringify(solucion));
  }

  modificarSolucion(solucion) {
    return this.http.post(`${this.URL}modificarSolucion.php`, JSON.stringify(solucion));
  }

  crearSolucion(solucion) {
    return this.http.post(`${this.URL}crearSolucion.php`, JSON.stringify(solucion));
  }

  solucionOnOff(solucion) {
    return this.http.post(`${this.URL}solucionOnOff.php`, JSON.stringify(solucion));
  }
  
  consultarSugerencia(punto_revision) {
    return this.http.post(`${this.URL}consultaSugerencias.php`, JSON.stringify(punto_revision));
  }

  crearSugerencia(sugerencia) {
    return this.http.post(`${this.URL}crearSugerencia.php`, JSON.stringify(sugerencia));
  }

  modificarSugerencia(sugerencia) {
    return this.http.post(`${this.URL}modificarSugerencia.php`, JSON.stringify(sugerencia));
  }

  sugerenciaOnOff(sugerencia) {
    return this.http.post(`${this.URL}sugerenciaOnOff.php`, JSON.stringify(sugerencia));
  }

  modificarPuntoRevision(punto) {
    return this.http.post(`${this.URL}modificarPuntoRevision.php`, JSON.stringify(punto));
  }

  usuarioOnOff(usuario) {
    return this.http.post(`${this.URL}usuarioOnOff.php`, JSON.stringify(usuario));
  }

  notificacionCambioNorma(texto) {
    let usuario=JSON.parse(localStorage.getItem("usuario"));
    //console.log("Notificacion cambio de norma");
    let textNoti={
      texto:texto.texto,
      rol:usuario.rol
    }
    return this.http.post(`${this.URL}notificacionCambioNorma.php`, JSON.stringify(textNoti));
  }

  unificarPDF(vacio) {
    return this.http.post(`${this.URL}unificarPdf.php`,JSON.stringify(vacio));
  }

  consultarNotificaciones(user) {
    return this.http.post(`${this.URL}consultaNotificacionesUsuario.php`,JSON.stringify(user));
  }

  notificacionLeida(user) {
    return this.http.post(`${this.URL}notificacionLeida.php`,JSON.stringify(user));
  }
  enviarNotificacionSuperUsuarios(texto){
    let usuario=JSON.parse(localStorage.getItem("usuario"));
    texto.texto="[" + usuario.nombre + " " + usuario.apellido + "] " + texto.texto;
    return this.http.post(`${this.URL}enviarNotificacionSuperUsuario.php`,JSON.stringify(texto));
  }
  
  enviarNotificacionSuper_Admin(texto){
    let usuario=JSON.parse(localStorage.getItem("usuario"));
    let notificacion={
     texto: "[" + usuario.nombre + " " + usuario.apellido + "] " + texto,
     rol:usuario.rol
    }
    return this.http.post(`${this.URL}notificacionSuperAdmin.php`,JSON.stringify(notificacion));
  }
  
  insertarLog(datos) {
    let usuario=JSON.parse(localStorage.getItem("usuario"));
    usuario["fecInicio"]=new Date;
    localStorage.setItem("usuario",JSON.stringify(usuario));
    return this.http.post(`${this.URL}insertarLog.php`,JSON.stringify(datos));
  }

  verificarLogin() {
    if (localStorage.getItem("usuario") != null) {
      let user=JSON.parse(localStorage.getItem("usuario"));
      let usuraio={
        usuario:user["user"]
      }
      return this.http.post(`${this.URL}verificarLogin.php`,JSON.stringify(usuraio));
    }
  }

  crearUsuario(usuario){
    return this.http.post(`${this.URL}crearUsuario.php`,JSON.stringify(usuario));
  }

  //Verifica si existen los links
  verificarLink(url){
    let info={
      url:url
    }
    return this.http.post(`${this.URL}validarFichero.php`,JSON.stringify(info));
  }

  //Verifica si existen los links
  verificarMultiplesLinks(url){
    let info={
      url:url
    }
    return this.http.post(`${this.URL}validarMultiplesFicheros.php`,JSON.stringify(info));
  }
  //Recibe el número de auditoría y tipo de documento para generar un informe en PDF
  crearDocumento(numero,documento,especialidad){
    let info={
      numero:numero,
      documento:documento,
      especialidad:especialidad
    }
    return this.http.post(`${this.URL}generarPDFs.php`,JSON.stringify(info));
  }

  //Recibe la cantidad de archivos que existen dentro de un directorio en el servidor
  obtenerElementosDirectorio(auditoria,especialidad){
    let info={
      auditoria:auditoria,
      especialidad:especialidad
    }
    return this.http.post(`${this.URL}buscarElementosDirectorio.php`,JSON.stringify(info));
  }

  //Recibe los logs según rango de fechas y criterio de búsqueda
  consultarLogs(fechaInicio,fechaFin,horaInicio,horaFin,busqueda){
    let info={
      fechaInicial:fechaInicio,
      fechaFin:fechaFin,
      horaInicial:horaInicio,
      horaFin:horaFin,
      busqueda:busqueda
    }
    return this.http.post(`${this.URL}buscarUserLog.php`,JSON.stringify(info));
  }

  //Recibe los logs según rango de fechas y criterio de búsqueda
  consultarLogsByAuditoria(busqueda,auditoria){
    let info={
      busqueda:busqueda,
      auditoria:auditoria
    }
    return this.http.post(`${this.URL}buscarUserLogByAuditoria.php`,JSON.stringify(info));
  }

  consultarVigenciaNorma(){
    return this.http.post(`${this.URL}consultarVigenciaNorma.php`,JSON.stringify({}));
  }

  cambiarVigenciaNorma(vigencia){
    let info={
      vigencia:vigencia
    }
    return this.http.post(`${this.URL}cambiarVigenciaNorma.php`,JSON.stringify(info));
  }

  consultarArchivosConfig(auditoria){
    let info={
      auditoria:auditoria
    }
    return this.http.post(`${this.URL}consultarArchivosConfig.php`,JSON.stringify(info));
  }
  //recibe el route para poder direccionar a la pagina de inicio     //this.loginService.validarHora(this.route);
  validarHora(route,tiempo){
    if (localStorage.getItem("usuario") != null) {
      let user=JSON.parse(localStorage.getItem("usuario"));
      var fecIniciomin=new Date(user["fecInicio"]).getTime();
      var fecActualmin=new Date().getTime();
      var dif=((fecActualmin-fecIniciomin)/(1000 * 60));
      /*var fecActual=new Date();
      var fecInicio=new Date(user["fecInicio"]);
      console.log("Fecha login:"+ fecInicio + "\n");
      console.log("Fecha limite:"+ dif + ' minutes' + "\n");
      console.log("Fecha Actual:"+ fecActual + "\n");*/
      if (dif>tiempo){
        //console.log("CERRAR SESION");
        let datos={
          user:user["user"]
        };
        this.logoutUsuario(datos).subscribe (
          datos=>{
            if (datos['estado']=="Exitoso"){
                //accion de ser necesaria
            }
          }
        );
        //Envia el LOG de cierre de sesion por inactividad
        let log={
          user:user["user"],
          descripcion:"Cierre por inactividad"
        };
        this.insertarLog(log).subscribe (
          datos=>{
            if (datos['estado']=="Exitoso"){
                //accion de ser necesaria
            }
          }
        );
        //console.log(datos);
        //Elimina el Local Store y lo lleva a la página de inicio.
        localStorage.removeItem("usuario");
        route.navigateByUrl('/inicio');
        return false;
      }else{
        return true;
      }
    }else{
      route.navigateByUrl('/inicio');
    }
    return false;
  }

mensajes(msj){
  switch (msj){
    case "pdfseguro": return "El archivo se encuentra protegido, remueva las restricciones e intente nuevamente"; 
  }
  return "texto de mensaje no encontrado";
}

}

