import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-provisionalc',
  templateUrl: './provisionalc.component.html',
  styleUrls: ['./provisionalc.component.css']
})
export class ProvisionalcComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
