<!-- Mensaje advertencia -->
<div *ngIf="advertencia"class="contenedoradvertencia"></div>
<div *ngIf="advertencia"class="contenedoradvertencia1">
  <div class="contenedorMensaje"> 
    <div class="btonalarma"><img type="button" (click)="onClickMe()" src="assets/img/btn_warning.png" width= 45px; alt=""></div>
    <div class ="encabezadoalarma"><img src="assets/img/en_warningp.png" width=375px; alt="warning">  </div>
    <div class="cuerpoalarma" style="font-family:'AvenirLTProMedium'; font-size: 25px; text-align: center;"> <p>{{msj}}</p></div>
  </div>
</div>
<!-- Fin mensaje advertencia -->

<!-- Mensaje Error contrasena-->
<div *ngIf="errorc"class="contenedoradvertencia"></div>
<div *ngIf="errorc"class="contenedoradvertencia1">
  <div class="contenedorMensaje"> 
    <div class="btonalarma"><img type="button" (click)="onClickMe()" src="assets/img/btn_error.png" width= 45px; alt=""></div>
    <div class ="encabezadoalarma"><img src="assets/img/en_errorp.png" width=375px; alt="error">  </div>
    <div class="cuerpoalarma" style="font-family:'AvenirLTProMedium'; font-size: 25px;text-align: center;" > <p>{{msj}}</p></div>
  </div>
</div>
<!-- Fin mensaje Error contrasena-->

  <!-- Mensaje Exitoso -->
  <div *ngIf="exitoso"class="contenedoradvertencia"></div>
  <div *ngIf="exitoso"class="contenedoradvertencia1">
    <div class="contenedorMensaje"> 
      <div class="btonalarma"><img type="button" (click)="onClickMe()" src="assets/img/btn_exitoso.png" width= 45px; alt=""></div>
      <div class ="encabezadoalarma"><img src="assets/img/exitosod.png" width=375px; alt="error">  </div>
      <div class="cuerpoalarma" style="font-family:'AvenirLTProMedium'; font-size: 25px;text-align: center;" > <p>{{msj}}</p></div>
    </div>
  </div>
  <!-- Fin mensaje Exitoso-->
  
<div class="main-content" style="background-image: url(./assets/img/fondo1.png);background-size: cover;">
    <div class="container-fluid" >
        <div class="row" align="center">
            <div class="col-md-12 "  >
                <div class="card opacity" >
                    <div class="card-header card-header-danger"style="cursor:default;">
                        <h4 class="card-title " style="text-align: left;font-family: 'AvenirLTProBlack'; font-size: 18px;">Versión de la norma ICREA</h4>
                        <p class="card-category" style="text-align: left;font-family: 'AvenirLTProMediumOblique';opacity: 0.9; font-size: 15px;"> Consulte y/o modifique el número de la versión actual de la norma</p>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-hover" >
                                <thead>
                                    <th COLSPAN="2">
                                        <div class="input-group" >
                                            <div class="form-control" >
                                              <form  [formGroup]="contactForm"  >
                                                <p class="card-category" style="text-align: left;font-family: 'AvenirLTProBlack'; margin:0; color:#2B405A;  font-size: 22px;">A continuación se encuentra el número de versión que actualmente se ve reflejado en la generación de reportes automáticos</p>
                                                <br>
                                                <p class="card-category" style="text-align: left;font-family: 'AvenirLTProBlack';opacity: 0.7; color:#2B405A;  font-size: 22px;">Número versión</p>
                                                <div class="input-group" >
                                                    <div class="form-control" >
                                                        <input type="text" id="inputModificar" class="input form-control" formControlName="numero_norma" (ngModelChange)="busChange()" 
                                                        [(ngModel)]="search" placeholder="Escriba el número de la versión de la norma"  class="form-control cc ajusteMargen " 
                                                        aria-label="Small" aria-describedby="inputGroup-sizing-sm" style="background: white; font-size: 18px;" disabled >
                                                        <div class="input-group" style="margin-top: 0px; ">
                                                            <div class="alert-danger" *ngIf="search==''" class="form-control" style="margin-top:-5px;">
                                                                <div class="container-error-message" style="font-size: 15px;" *ngIf="search==''">
                                                                    El campo es requerido.
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button class="btn btn-dark botonCrear" (click)="modificar()"style=" margin-left: 10px; cursor: pointer;"  [disabled]="disModificar" >MODIFICAR</button>
                                                    <button class="btn btn-dark botonCrear" (click)="guardar()" style="margin-left: 10px; cursor: pointer;" [disabled]="disGuardar">GUARDAR</button>                                                                                       
                                                  </div>
                                                </form>
                                            </div>
                                        </div>
                                    </th>
                                    
                                </thead>
                                <!-- Contenedor para estado inicial-->
                                <tbody style=" border: none; margin-top: 10px; font-family: 'AvenirLTProBlack';">
                                    <th></th>
                                    <th>
                                        <div   style="padding-bottom: 70px; padding-top: 45px;"> 
                                            
                                        </div>
                                    </th>                               
                                </tbody>
                                <!-- Fin Contenedor para estado inicial-->
                            </table>
                            <div *ngIf="active" class="loading"><img src="assets/img/loading.gif" width="200px" alt="splash" ></div>
                            <div id="btnAtras"  class="atras" (click)="agregarAtras()"> </div> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

