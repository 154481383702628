<html>
    <head>
        <title></title>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1">
        <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1" />
  
         <!-- Los iconos tipo Solid de Fontawesome-->
      <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.0.8/css/solid.css">
      <script src="https://use.fontawesome.com/releases/v5.0.7/js/all.js"></script>
      <style type="text/css">
        .figure{
                margin-left: 27px;
                margin-top: 18px;
            }
            .opacity{
                opacity:100%;
            }


            .form-group input{
                height: 42px;
                font-size: 18px;
                border:0;
                background-color: rgba(255, 255, 255, 1);
                padding-left: 54px;
                border-radius: 5px;
            }


            @font-face {
                font-family:"AvenirNextRoundedStd-Bold.ttf";
                src: url(/assets/letras/AvenirNextRoundedStd-Bold.ttf) format("trueType");
                
            }

            @font-face {
                font-family: "AvenirLTProBlack";
                font-style: normal;
                src:url(/assets/fonts/AvenirLTProBlack.otf);
            }


            @font-face {
                font-family: "AvenirLTProMediumOblique";
                font-style: italic;
                src:url(/assets/fonts/AvenirLTProMediumOblique.otf);
            }

            @font-face {
                font-family: "AvenirLTProMedium";
                font-style: normal;
                src:url(/assets/fonts/AvenirLTProMedium.otf);
            }

            @font-face {
                font-family: "AvenirLTProLight";
                font-style: normal;
                src:url(/assets/fonts/AvenirLTProLight.otf);
            }

            @font-face {
                font-family: "AvenirLTProLightOblique";
                font-style: italic;
                src:url(/assets/fonts/AvenirLTProLightOblique.otf);
            }


            @media screen {

                
            
                @font-face {
                    font-family: "AvenirLTProHeavy";
                    font-style: normal;
                    font-weight: 700;
                    src: local("Lato Bold"), local("Lato-Bold"), url(/assets/fonts/AvenirLTProHeavy.otf);
                }

                @font-face {
                    font-family: "AvenirLTProLight";
                    font-style: normal;
                    font-weight: 400;
                    src: local("Lato Italic"), local("Lato-Italic"), url(/assets/fonts/AvenirLTProLight.otf);
                }

                @font-face {
                    font-family: "AvenirLTProOblique";
                    font-style: italic;
                    font-weight: 700;
                    src: local("Lato Bold Italic"), local("Lato-BoldItalic"), url(/assets//AvenirLTProOblique.otf);
                }
            }

            /* CLIENT-SPECIFIC STYLES */
            body,
            table,
            td,
            a {
                -webkit-text-size-adjust: 100%;
                -ms-text-size-adjust: 100%;
            }

            table,
            td {
                -o-table-baseline: 0pt;
                -o-table-baseline: 0pt;
            }

            img {
                -ms-interpolation-mode: bicubic;
            }

            /* RESET STYLES */


            table {
                border-collapse: collapse !important;
            }

            body {
                min-height: 100%;
                height: auto !important;
                margin: 0 !important;
                padding: 0 !important;
                width: 100% !important;
                background: url(/assets/img/fondorecupc.png) repeat center center ;
                background-size: cover;
            }

            /* iOS BLUE LINKS */
            a[x-apple-data-detectors] {
                color: inherit !important;
                text-decoration: none !important;
                font-size: inherit !important;
                font-family: inherit !important;
                font-weight: inherit !important;
                line-height: inherit !important;
            }

            /* MOBILE STYLES */
            @media screen and (max-width:300px) {
                h1 {
                    font-size: 18px !important;
                    line-height: 18px !important;
                    font-family: 'AvenirLTProMediumOblique';
                }
                .insignia{
                    margin-left: 20px;
                    margin-top: 50px;
                    text-size-adjust: 22px;
                    margin-right: 20px;
                }
                body {
                    
                    height: auto !important;
                    margin: 0 !important;
                    padding: 0 !important;
                    width: 100% !important;
                    background: url(/assets/img/fondorecupc.png) repeat center center ;
                    background-size: cover;
                }


            }

            /* ANDROID CENTER FIX */
            div[style*="margin: 16px 0;"] {
                margin: 0 !important;

            }
    </style>
    </head>

   <body style=" margin: 0 !important; padding: 0 !important;">

    <table border="0" cellpadding="0" cellspacing="0" width="100%">
   
        <!-- Tabla encabezado-->
          <tr>
            <td align="left">
                <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 800px;">
                    <tr>
                        <td align="center" valign="top" style="padding: 70px 20px 0px 20px;"> </td>
                    </tr>
                </table>
            </td>
        </tr>
        <!-- Fin tabla de encabezado-->

         <!-- Tabla formulario-->
  
         <tr>  <!--  encabezado-->
            <td  align="center" style="padding: 0px 20px 0px 20px;">
                <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width:800px;">
                    <tr>
    
                       <td class="opacity" bgcolor="#FFFFFF" align="center" valign="top" style="padding: 0px 20px 0px 20px; border-radius: 20px 20px 0px 0px; color: #2B405A; font-family:'AvenirLTProBlack';">
                        <div ><img src="../../assets/img/logoicrea.jpg" style="width: 200px; margin-top:25px;" alt=""> </div>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>  <!-- Fin encabezado-->

        <!-- cuerpo formulario-->
      <tr>
        <td  align="center" style="padding: 0px 20px 80px 20px;">
            <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 800px;">
                <tr>
                    <td class="opacity" bgcolor="#FFFFFF" align="center" style="padding: 10px 15px 0px 15px; color: #2B405A; font-family: 'AvenirLTProLight'; font-size: 18px;">
                     <!-- Validacion correo-->
                      <form class="col-12">
                        <div class="form-group" id="user-group">
                            <!-- Validaciones correo-->
                            <div style="text-align: justify; font-family: 'AvenirLTProLight';" ><p>Hola <span style="color: #0B5077;"><strong>Carolina Albornoz</strong></span></p></div>
                            <div style="text-align: justify; margin-top: 20px; font-family: 'AvenirLTProLight';"> Se ha recibido una solicitud de contraseña provisional a su nombre, si no ha sido usted ignore este mensaje, de lo contrario haga clic en el siguiente botón para continuar con el proceso de recuperación de contraseña.</div>
                            <br>
                            <tr>
                                <td align="center" style="border-radius: 15px;" bgcolor="#2B405A"><a href="http://www.virtualrz.com/webservices/recuperarCuenta.php?a='. $codigo.'&b='.$pin.'" target="_blank" style="font-size: 16px; font-family: AvenirLTProBlack; color: #ffffff; text-decoration: none; color: #ffffff; text-decoration: none; padding: 5px 50px; border-radius: 30px; border: 1px solid #0B5077; display: inline-block;">RECUPERAR CONTRASEÑA</a></td>
                            </tr>

                            <div style="text-align: justify; margin-top: 20px; font-family: 'AvenirLTProLight';">
                                Si tiene alguna pregunta, responda a este correo electrónico, siempre estamos felices de ayudarle.
                            </div>
                            
                        </div>
                        <!-- fin validacion correo-->
                      
                    </form>
        
                    </td>
                </tr>
                <tr>
                    <td class="opacity" bgcolor="#FFFFFF" align="left" style="padding: 20px 0px 50px 15px; border-radius: 0px 0px 20px 20px; color: #2B405A; font-family: 'AvenirLTProMedium'; font-size: 18px; font-weight: 400; line-height: 25px;">
                      <div class="col-12 olvidopas">
                         <a  style="color:#2B405A ;  font-family: 'AvenirLTProLight'">Equipo Audit-ICREA</a>
                     </div>
                    </td>
                </tr>
            </table>

            <tr>
                <td  align="center" style="margin-top: 0px;">
                    <table border="0" cellpadding="0" cellspacing="0" width="100%" >
                        <tr>
                            <td align="center" style="color: #FFFF; font-family:'AvenirLTProMediumOblique'; font-size: 18px;padding-top: 10px;">
                                <div>
                                <a  style="color:#000000 ;  font-family: 'AvenirLTProLightOblique'; font-size: 17px; ">© Audit-ICREA 2020</a>
                              </div>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
        </td>
    </tr>

      <!-- Fin tabla formulario-->

      
        
  
  </table>
        
    </body>
</html>
