import {
  Component,
  OnInit,
} from '@angular/core';
import { LoginServiceService } from '../login-service.service';
import { Router } from '@angular/router';
import { _MatTabBodyBase } from '@angular/material/tabs';
import { DatePipe } from '@angular/common';
import 'rxjs/add/observable/timer';
import * as JSZip from 'jszip';
import { of } from 'rxjs';
declare var require: any
const FileSaver = require('file-saver');
@Component({
  selector: 'app-verreportes',
  templateUrl: './verreportes.component.html',
  styleUrls: ['./verreportes.component.css']
})
export class VerreportesComponent implements OnInit {
  // -------------- variables relacionadas a la pantalla y listas
  auditoria;
  especialidad = localStorage.getItem("especialidadReporte").toLocaleLowerCase();
  public advertencia: boolean;
  msj: string;
  us: any;
  user: any;
  active = false;
  especialidadPlano = '';
  numeroEspecialidad = '';
  elementosDirectorio = [];
  linksTotales = [];

  // variables boleeanas para habilitar o deshabilitar carboard
  public equipoMedicion: boolean = false;
  public infraestructuraGraficas: boolean = false;
  public evaluacionTecnica: boolean = false;
  public conclusionesTecnicas: boolean = false;
  public reporteCedula: boolean = false;
  public reporteTermografico: boolean = false;
  public reporteComplementarios: boolean = false;
  public todosLosReportes: boolean = false;

  constructor(private router: Router,private loginService: LoginServiceService,public datePipe: DatePipe) {
    this.loginService.validarHora(
      this.router,
      this.loginService.TIEMPO_INACTIVIDAD / 60
    );
    // Carga la informacion de las notificaciones sin leer
    if (localStorage.getItem('usuario') != null) {
      this.us = JSON.parse(localStorage.getItem('usuario'));
      let localStr = JSON.parse(localStorage.getItem('usuario'));
      let user = {
        correo: localStr['correo'],
      };
      this.loginService.consultarNotificaciones(user).subscribe((datos) => {
        if (datos['mensaje'] == 'Exitoso') {
          localStr.newNot = datos['sinleer'];
          localStorage.setItem('usuario', JSON.stringify(localStr));
        }
      });
    } else {
      this.router.navigateByUrl('/inicio');
    }
    this.asignarNumeroEspecialidad();
    //Carga de la información
    if (this.loginService.vartemp != null) {
      this.auditoria = this.loginService.vartemp;
      this.diferenciaDias();
      this.habilitarBotones();
    } else {
      //Se recargo la pagina y se perdio la información de la auditoria, retorna a la pagina anterior.
      this.router.navigateByUrl('/consultarReportes');
    }
  }
  // -------------- Fin Validacion roles y activacion boton eliminar segun rol --------------- //

  ngOnInit(): void { }

  agregarAtras() {
    // Redireccionamos a la pagina de reportes
    this.router.navigateByUrl('/reportes');
  }

  //Obtener la diferencia de días entre el día final de la auditoría y la fecha actual
  diferenciaDias() {
    let fechaActual = new Date().getTime();
    if (this.auditoria.fecrev_ini) {
      if (this.auditoria.fecrev_ini !== '0000-00-00') {
        let fechaFin = new Date(this.auditoria.fecrev_ini).getTime();
        let diff = Math.round((fechaFin - fechaActual) / (1000 * 60 * 60 * 24));
        this.auditoria.diferenciaDias = diff + 30;
      }
    } else {
      this.auditoria.diferenciaDias = null;
    }
  }

  //Dejar de mostrar la advertencia
  onClickMe() {
    this.advertencia = false;
  }

  //Mostrar una alerta que contiene el mensaje asignado
  alerta(msj) {
    this.msj = msj;
    this.advertencia = true;
  }

  //se verifican las condiciones para habilita cédula de seguimiento, reporte termográfico y documentos complementarios
  habilitarBotones() {
    if (this.auditoria.numero_anterior != 0 && this.auditoria.numero_anterior !== null && this.auditoria.numero_anterior !== undefined) {
      this.reporteCedula = true;
    }
    this.verificarLinks(`../auditorias/${this.auditoria.numero}/${this.especialidadPlano}/equipoM/EQUIPO_DE_MEDICION_UTILIZADO.pdf`, 'equipoMedicion')
    this.verificarLinks(`../auditorias/${this.auditoria.numero}/${this.especialidadPlano}/infraestructuraG/INFRAESTRUCTURA_Y_GRAFICAS.pdf`, 'infraestructuraGraficas')
    this.verificarLinks(`../auditorias/${this.auditoria.numero}/${this.especialidadPlano}/evaluacionT/EVALUACION_TECNICA.pdf`, 'evaluacionTecnica')
    this.verificarLinks(`../auditorias/${this.auditoria.numero}/${this.especialidadPlano}/conclusionesT/CONCLUSIONES_TECNICAS.pdf`, 'conclusionesTecnicas')
    this.verificarLinks(`../auditorias/${this.auditoria.numero}/${this.especialidadPlano}/reporteT/REPORTE_TERMOGRAFICO.pdf`, 'reporteTermografico')
    this.verificarLinks(`../auditorias/${this.auditoria.numero}/informes/documentosC/${this.numeroEspecialidad}`, 'reporteComplementarios')
    this.consultarArchivosConfig()
  }

  //Se verifica que los links enviados existan y de ser así se habilita el botón respectivo
  verificarLinks(link, tipo) {
    this.loginService
      .verificarLink(link)
      .subscribe(
        (datos) => {
          if (datos['mensaje'] == 'Existe') {
            if (tipo == 'reporteTermografico') {
              this.reporteTermografico = true;
            } else if (tipo == 'reporteComplementarios') {
              this.reporteComplementarios = true;
            } else if (tipo == 'equipoMedicion') {
              this.equipoMedicion = true;
            }  else if (tipo == 'infraestructuraGraficas' || tipo == 'infraestructura_Graficas') {
              console.log(tipo);
              this.infraestructuraGraficas = true;
            }  else if (tipo == 'evaluacionTecnica' || tipo == 'evaluacion_Tecnica') {
              console.log(tipo);
              this.evaluacionTecnica = true;
            }  else if (tipo == 'conclusionesTecnicas' || tipo == 'conclusiones_Tecnicas') {
              console.log(tipo);
              this.conclusionesTecnicas = true;
            } else if (tipo == 'CÉDULA DE SEGUIMIENTO') {
              const pdfUrl = "https://audit-icrea.com/auditorias/" + this.auditoria.numero_anterior + "/" + this.especialidadPlano + "/cedulaS/CEDULA_DE_SEGUIMIENTO.pdf";
              const pdfName = this.numeroEspecialidad + '-2 Cédula de seguimiento.pdf';
              this.downloadPDF(pdfUrl, pdfName);
            } else if (tipo == 'REPORTE FOTOGRÁFICO') {
              const pdfUrl = "https://audit-icrea.com/auditorias/" + this.auditoria.numero + "/" + this.especialidadPlano + "/reporteF/REPORTE_FOTOGRAFICO.pdf";
              const pdfName = this.numeroEspecialidad + '-3 Reporte Fotográfico.pdf';
              this.downloadPDF(pdfUrl, pdfName);
            } else if (tipo == 'REPORTE TERMOGRÁFICO') {
              const pdfUrl = "https://audit-icrea.com/auditorias/" + this.auditoria.numero + "/" + this.especialidadPlano + "/reporteT/REPORTE_TERMOGRAFICO.pdf";
              const pdfName = this.numeroEspecialidad + '-4 Reporte termográfico.pdf';
              this.downloadPDF(pdfUrl, pdfName);
            } else if (tipo == 'INFRAESTRUCTURA Y GRÁFICAS') {
              const pdfUrl = "https://audit-icrea.com/auditorias/" + this.auditoria.numero + "/" + this.especialidadPlano + "/infraestructuraG/INFRAESTRUCTURA_Y_GRAFICAS.pdf";
              const pdfName = this.numeroEspecialidad + '-5 Infraestructura y gráficas.pdf';
              this.downloadPDF(pdfUrl, pdfName);
            } else if (tipo == 'EVALUACIÓN TÉCNICA') {
              const pdfUrl = "https://audit-icrea.com/auditorias/" + this.auditoria.numero + "/" + this.especialidadPlano + "/evaluacionT/EVALUACION_TECNICA.pdf";
              const pdfName = this.numeroEspecialidad + '-6 Evaluación técnica.pdf';
              this.downloadPDF(pdfUrl, pdfName);
            } else if (tipo == 'CONCLUSIONES TÉCNICAS') {
              const pdfUrl = "https://audit-icrea.com/auditorias/" + this.auditoria.numero + "/" + this.especialidadPlano + "/conclusionesT/CONCLUSIONES_TECNICAS.pdf";
              const pdfName = this.numeroEspecialidad + '-7 Conclusiones técnicas.pdf';
              this.downloadPDF(pdfUrl, pdfName);
            } else if (tipo == 'OBSERVACIONES Y SUGERENCIAS') {
              const pdfUrl = "https://audit-icrea.com/auditorias/" + this.auditoria.numero + "/" + this.especialidadPlano + "/cedulaO/CEDULA_DE_OBSERVACIONES_Y_SUGERENCIAS.pdf";
              const pdfName = this.numeroEspecialidad+'-8 Cédula de observaciones y sugerencias.pdf';
              this.downloadPDF(pdfUrl, pdfName);
            } else if (tipo == 'GUÍA DE CUMPLIMIENTO') {
              const pdfUrl = "https://audit-icrea.com/auditorias/" + this.auditoria.numero + "/" + this.especialidadPlano + "/documentosR/GUIA_DE_REVISION_DE_CUMPLIMIENTO.pdf";
              const pdfName = this.numeroEspecialidad+'-9 Guía de cumplimiento.pdf';
              this.downloadPDF(pdfUrl, pdfName);
            } else if (tipo == 'EQUIPO DE MEDICIÓN') {
              const pdfUrl = "https://audit-icrea.com/auditorias/" + this.auditoria.numero + "/" + this.especialidadPlano + "/equipoM/EQUIPO_DE_MEDICION_UTILIZADO.pdf";
              const pdfName = this.numeroEspecialidad + '-1 Equipo de medición';
              this.downloadPDF(pdfUrl, pdfName);
            }
          } else {
            if (tipo == 'reporteTermografico') {
              this.reporteTermografico = false;
            } else if (tipo == 'reporteComplementarios') {
              this.reporteComplementarios = false;
            }  else if (tipo == 'infraestructuraGraficas') {
              this.verificarLinks(`../auditorias/${this.auditoria.numero}/dispositivo/${this.numeroEspecialidad}/infraestructura/config.txt`, 'infraestructura_Graficas')
            }  else if (tipo == 'evaluacionTecnica') {
              this.verificarLinks(`../auditorias/${this.auditoria.numero}/dispositivo/${this.numeroEspecialidad}/evaluacion/config.txt`, 'evaluacion_Tecnica')
            }  else if (tipo == 'conclusionesTecnicas') {
              this.verificarLinks(`../auditorias/${this.auditoria.numero}/dispositivo/${this.numeroEspecialidad}/conclusiones/config.txt`, 'conclusiones_Tecnicas')
            } else if (tipo == 'CÉDULA DE SEGUIMIENTO') {
              this.alerta('NO tiene cédula de seguimiento esta especialidad');
              this.active = false;
            } else if (tipo == 'REPORTE FOTOGRÁFICO') {
              this.loginService
                .crearDocumento(this.auditoria.numero, "ReporteFotografico", this.especialidadPlano)
                .subscribe(
                  (datos) => {
                    if (datos['mensaje'] == "Exitoso") {
                      const pdfUrl = "https://audit-icrea.com/auditorias/" + this.auditoria.numero + "/" + this.especialidadPlano + "/reporteF/REPORTE_FOTOGRAFICO.pdf";
                      const pdfName = this.numeroEspecialidad + '-3 Reporte Fotográfico';
                      this.downloadPDF(pdfUrl, pdfName);
                    } else {
                      this.alerta('Lo sentimos, hubo un fallo descargando el archivo.');
                    }
                    this.active = false;
                  },
                  (error) => {
                    this.alerta('Lo sentimos, hubo un problema de conexión con el servidor intente más tarde.');
                    this.active = false;
                  }
                );
            } else if (tipo == 'REPORTE TERMOGRÁFICO') {
              this.alerta('NO tiene reporte termográfico esta especialidad');
              this.active = false;
            } else if (tipo == 'INFRAESTRUCTURA Y GRÁFICAS') {
              this.loginService
                .crearDocumento(this.auditoria.numero, "InfraestructuraGraficas", this.especialidadPlano)
                .subscribe(
                  (datos) => {
                    if (datos['mensaje'] == "Exitoso") {
                      const pdfUrl = "https://audit-icrea.com/auditorias/" + this.auditoria.numero + "/" + this.especialidadPlano + "/infraestructuraG/INFRAESTRUCTURA_Y_GRAFICAS.pdf";
                      const pdfName = this.numeroEspecialidad + '-5 Infraestructura y gráficas.pdf';
                      this.downloadPDF(pdfUrl, pdfName);
                    } else {
                      this.alerta('Lo sentimos, hubo un fallo descargando el archivo.');
                    }
                    this.active = false;
                  },
                  (error) => {
                    this.alerta('Lo sentimos, hubo un problema de conexión con el servidor intente más tarde.');
                    this.active = false;
                  }
                );
            } else if (tipo == 'EVALUACIÓN TÉCNICA') {
              this.loginService
                .crearDocumento(this.auditoria.numero, "EvaluacionTecnica", this.especialidadPlano)
                .subscribe(
                  (datos) => {
                    if (datos['mensaje'] == "Exitoso") {
                      const pdfUrl = "https://audit-icrea.com/auditorias/" + this.auditoria.numero + "/" + this.especialidadPlano + "/evaluacionT/EVALUACION_TECNICA.pdf";
                      const pdfName = this.numeroEspecialidad + '-6 Evaluación técnica.pdf';
                      this.downloadPDF(pdfUrl, pdfName);
                    } else {
                      this.alerta('Lo sentimos, hubo un fallo descargando el archivo.');
                    }
                    this.active = false;
                  },
                  (error) => {
                    this.alerta('Lo sentimos, hubo un problema de conexión con el servidor intente más tarde.');
                    this.active = false;
                  }
                );
            } else if (tipo == 'CONCLUSIONES TÉCNICAS') {
              this.loginService
                .crearDocumento(this.auditoria.numero, "ConclusionesTecnicas", this.especialidadPlano)
                .subscribe(
                  (datos) => {
                    if (datos['mensaje'] == "Exitoso") {
                      const pdfUrl = "https://audit-icrea.com/auditorias/" + this.auditoria.numero + "/" + this.especialidadPlano + "/conclusionesT/CONCLUSIONES_TECNICAS.pdf";
                      const pdfName = this.numeroEspecialidad + '-7 Conclusiones técnicas.pdf';
                      this.downloadPDF(pdfUrl, pdfName);
                    } else {
                      this.alerta('Lo sentimos, hubo un fallo descargando el archivo.');
                    }
                    this.active = false;
                  },
                  (error) => {
                    this.alerta('Lo sentimos, hubo un problema de conexión con el servidor intente más tarde.');
                    this.active = false;
                  }
                );
            } else if (tipo == 'OBSERVACIONES Y SUGERENCIAS') {
              this.loginService
              .crearDocumento(this.auditoria.numero, "ObservacionesSugerencias",this.especialidadPlano)
              .subscribe(
                (datos) => {
                  if (datos['mensaje'] == "Exitoso") {
                    const pdfUrl = "https://audit-icrea.com/auditorias/" + this.auditoria.numero +"/" + this.especialidadPlano + "/cedulaO/CEDULA_DE_OBSERVACIONES_Y_SUGERENCIAS.pdf";
                    const pdfName = this.numeroEspecialidad+'-8 Cédula de observaciones y sugerencias';
                    this.downloadPDF(pdfUrl, pdfName);
                  } else {
                    this.alerta('Lo sentimos, hubo un fallo descargando el archivo.');
                  }
                  this.active = false;
                },
                (error) => {
                    this.alerta('Lo sentimos, hubo un problema de conexión con el servidor intente más tarde.');
                  this.active = false;
                }
              );
            } else if (tipo == 'GUÍA DE CUMPLIMIENTO') {
              this.loginService
              .crearDocumento(this.auditoria.numero, "GuiaCumplimiento",this.especialidadPlano)
              .subscribe(
                (datos) => {
                  if (datos['mensaje'] == "Exitoso") {
                    const pdfUrl = "https://audit-icrea.com/auditorias/" + this.auditoria.numero +"/" + this.especialidadPlano + "/documentosR/GUIA_DE_REVISION_DE_CUMPLIMIENTO.pdf";
                    const pdfName = this.numeroEspecialidad+'-9 Guía de cumplimiento.pdf';
                    this.downloadPDF(pdfUrl, pdfName);
                  } else {
                    this.alerta('Lo sentimos, hubo un fallo descargando el archivo.');
                  }
                  this.active = false;
                },
                (error) => {
                    this.alerta('Lo sentimos, hubo un problema de conexión con el servidor intente más tarde.');
                  this.active = false;
                }
              );
            }
          }
        },
        (error) => {
          if (tipo == 'reporteTermografico') {
            this.reporteTermografico = false;
          } else if (tipo == 'reporteComplementarios') {
            this.reporteComplementarios = false;
          } else if (tipo == 'CÉDULA DE SEGUIMIENTO' || tipo=='REPORTE FOTOGRÁFICO' || tipo=='REPORTE TERMOGRÁFICO' || tipo == 'INFRAESTRUCTURA Y GRÁFICAS' || tipo == 'EVALUACIÓN TÉCNICA' || tipo == 'CONCLUSIONES TÉCNICAS' || tipo == 'OBSERVACIONES Y SUGERENCIAS' || tipo == 'GUÍA DE CUMPLIMIENTO') {
            this.alerta('No fue posible realizar la descarga del archivo solicitado, intente más tarde');
            this.active = false;
          }
        }
      );
  }

  //Descargar PDF según el tipo de documento solicitado
  obtenerPDF(tipo) {
    this.active = true;
    if (tipo == 'EQUIPO DE MEDICIÓN') {
      this.verificarLinks(`../auditorias/${this.auditoria.numero}/${this.especialidadPlano}/equipoM/EQUIPO_DE_MEDICION_UTILIZADO.pdf`, 'EQUIPO DE MEDICIÓN')
    } else if (tipo == 'CÉDULA DE SEGUIMIENTO') {
      this.verificarLinks(`../auditorias/${this.auditoria.numero_anterior}/${this.especialidadPlano}/cedulaS/CEDULA_DE_SEGUIMIENTO.pdf`, 'CÉDULA DE SEGUIMIENTO')
    } else if (tipo == 'REPORTE FOTOGRÁFICO') {
      this.verificarLinks(`../auditorias/${this.auditoria.numero}/${this.especialidadPlano}/reporteF/REPORTE_FOTOGRAFICO.pdf`, 'REPORTE FOTOGRÁFICO');
    } else if (tipo == 'REPORTE TERMOGRÁFICO') {
      this.verificarLinks(`../auditorias/${this.auditoria.numero}/${this.especialidadPlano}/reporteT/REPORTE_TERMOGRAFICO.pdf`, 'REPORTE TERMOGRÁFICO')
    } else if (tipo == 'INFRAESTRUCTURA Y GRÁFICAS') {
      this.verificarLinks(`../auditorias/${this.auditoria.numero}/${this.especialidadPlano}/infraestructuraG/INFRAESTRUCTURA_Y_GRAFICAS.pdf`, 'INFRAESTRUCTURA Y GRÁFICAS')
    } else if (tipo == 'EVALUACIÓN TÉCNICA') {
      this.verificarLinks(`../auditorias/${this.auditoria.numero}/${this.especialidadPlano}/evaluacionT/EVALUACION_TECNICA.pdf`, 'EVALUACIÓN TÉCNICA')
    } else if (tipo == 'CONCLUSIONES TÉCNICAS') {
      this.verificarLinks(`../auditorias/${this.auditoria.numero}/${this.especialidadPlano}/conclusionesT/CONCLUSIONES_TECNICAS.pdf`, 'CONCLUSIONES TÉCNICAS')
    } else if (tipo == 'OBSERVACIONES Y SUGERENCIAS') {
      this.verificarLinks(`../auditorias/${this.auditoria.numero}/${this.especialidadPlano}/cedulaO/CEDULA_DE_OBSERVACIONES_Y_SUGERENCIAS.pdf`, 'OBSERVACIONES Y SUGERENCIAS')
    } else if (tipo == 'GUÍA DE CUMPLIMIENTO') {
      this.verificarLinks(`../auditorias/${this.auditoria.numero}/${this.especialidadPlano}/documentosR/GUIA_DE_REVISION_DE_CUMPLIMIENTO.pdf`, 'GUÍA DE CUMPLIMIENTO')
    } else if (tipo == 'DOCUMENTOS COMPLEMENTARIOS') {
      this.obtenerElementosDirectorio('directorio');
    } else if (tipo == 'TODOS LOS REPORTES') {
      this.comprobarTotalLinks();
    }
  }

  consultarArchivosConfig() {
    this.loginService
      .consultarArchivosConfig(this.auditoria.numero)
      .subscribe(
        (datos) => {
            let estados = datos['estados'][0];
            if (estados['estado'+this.numeroEspecialidad] == "2"){
              this.todosLosReportes=true;
            }
          },
        (error) => {
          this.alerta('Lo sentimos, hubo un problema de conexión con el servidor intente más tarde.');
          this.active = false;
          }
      );
  }


  //realizar la descarga del pdf a partir de la URL
  downloadPDF(pdfUrl, pdfName) {
    const myObservable = of(1);
    const myObserver = {
      next: x => FileSaver.saveAs(pdfUrl, pdfName),
      error: err => this.alerta('No se pudo generar el PDF correctamente'),
      complete: () => {
        this.insertarLog(pdfName,"PDF");
        this.active = false;
      },
    };
    myObservable.subscribe(myObserver);
  }

  //Añadir diferentes archivos a un ZIP y descargarlo
  downloadZIP(tipo){
    let zip: JSZip = new JSZip();
    let nombreTotal = '';
    if (tipo=='directorio'){
      this.elementosDirectorio.forEach(element => {
        let url="https://audit-icrea.com/auditorias/"+this.auditoria.numero+"/informes/documentosC/"+this.numeroEspecialidad+"/"+element;
        let blob = fetch(url).then(r => r.blob());
        let nombre = this.numeroEspecialidad+"-10 Documentos complementarios "+element;
        zip.file(nombre, blob, { binary: true });
      });
      nombreTotal=this.numeroEspecialidad+"-10 Documentos complementarios.zip";
    }else if (tipo=='todos'){
      this.linksTotales.forEach(element => {
        let obtenerNombre = element.split("/");
        let blob = fetch(element).then(r => r.blob());
        let nombre = this.obtenerNombrePDF(obtenerNombre[obtenerNombre.length-1]);
        zip.file(nombre, blob, { binary: true });
      });
      this.elementosDirectorio.forEach(element => {
        let url="https://audit-icrea.com/auditorias/"+this.auditoria.numero+"/informes/documentosC/"+this.numeroEspecialidad+"/"+element;
        let blob = fetch(url).then(r => r.blob());
        let nombre = this.numeroEspecialidad+"-10 Documentos complementarios "+element;
        zip.file(nombre, blob, { binary: true });
      });
      nombreTotal=this.obtenerNombreZIP();
    }

    //Generación del ZIP
    zip.generateAsync({type:"blob"}).then((content)=> {
      const myObservable = of(1);
      const myObserver = {
        next: x => FileSaver.saveAs(content, nombreTotal),
        error: err => this.alerta('No se pudo generar el ZIP correctamente'),
        complete: () => {
          this.insertarLog(nombreTotal,"ZIP");
          this.active = false;
        },
      };
      myObservable.subscribe(myObserver);
    });

  }

  //se obtienen los elementos dentro del directorio para ser descargados
  obtenerElementosDirectorio(tipo){
    this.loginService
    .obtenerElementosDirectorio(this.auditoria.numero, this.numeroEspecialidad)
    .subscribe(
      (datos) => {
        if (datos['mensaje'] !== "No existen coincidencias") {
          this.elementosDirectorio=datos['mensaje'].split(",");
          if (tipo=='directorio'){
            this.downloadZIP('directorio');
          } else if(tipo=='todos'){
            this.downloadZIP('todos');
          }
        }else{
          if (tipo=='directorio'){
            this.alerta('Esta especialidad no tiene documentos complementarios.');
            this.active = false;
          } else if(tipo=='todos'){
            this.downloadZIP('todos');
          }
        }
      },
      (error) => {
          this.alerta('Lo sentimos, hubo un problema de conexión con el servidor intente más tarde.');
          this.active = false;
      }
    );
  }

  //se comprueban los links de todos los reportes
  comprobarTotalLinks(){
    this.linksTotales = [];
    let links=`../auditorias/${this.auditoria.numero}/${this.especialidadPlano}/equipoM/EQUIPO_DE_MEDICION_UTILIZADO.pdf`;
    links=links+`,,,../auditorias/${this.auditoria.numero_anterior}/${this.especialidadPlano}/cedulaS/CEDULA_DE_SEGUIMIENTO.pdf`;
    links=links+`,,,../auditorias/${this.auditoria.numero}/${this.especialidadPlano}/reporteF/REPORTE_FOTOGRAFICO.pdf`;
    links=links+`,,,../auditorias/${this.auditoria.numero}/${this.especialidadPlano}/reporteT/REPORTE_TERMOGRAFICO.pdf`;
    links=links+`,,,../auditorias/${this.auditoria.numero}/${this.especialidadPlano}/infraestructuraG/INFRAESTRUCTURA_Y_GRAFICAS.pdf`;
    links=links+`,,,../auditorias/${this.auditoria.numero}/${this.especialidadPlano}/evaluacionT/EVALUACION_TECNICA.pdf`;
    links=links+`,,,../auditorias/${this.auditoria.numero}/${this.especialidadPlano}/conclusionesT/CONCLUSIONES_TECNICAS.pdf`;
    links=links+`,,,../auditorias/${this.auditoria.numero}/${this.especialidadPlano}/cedulaO/CEDULA_DE_OBSERVACIONES_Y_SUGERENCIAS.pdf`;
    links=links+`,,,../auditorias/${this.auditoria.numero}/${this.especialidadPlano}/documentosR/GUIA_DE_REVISION_DE_CUMPLIMIENTO.pdf`;
    this.loginService
    .verificarMultiplesLinks(links)
    .subscribe(
      (datos) => {
        datos['elementos'].forEach(element => {
          this.linksTotales.push(element.replace("..","https://audit-icrea.com"));
        });
        this.obtenerElementosDirectorio('todos');
      },
      (error) => {
          this.alerta('Lo sentimos, hubo un problema de conexión con el servidor intente más tarde.');
          this.active = false;
      }
    );
  }
  //Se asigna el número para verificar en links relacionado a cada especialidad de la auditoría
  asignarNumeroEspecialidad() {
    const acentos = { 'á': 'a', 'é': 'e', 'í': 'i', 'ó': 'o', 'ú': 'u', 'Á': 'A', 'É': 'E', 'Í': 'I', 'Ó': 'O', 'Ú': 'U' };
    this.especialidadPlano = this.especialidad.split('').map(letra => acentos[letra] || letra).join('').toString();
    if (this.especialidadPlano == "electrico") {
      this.numeroEspecialidad = "420";
    } else if (this.especialidadPlano == "climatizacion") {
      this.numeroEspecialidad = "430";
    } else if (this.especialidadPlano == "seguridad") {
      this.numeroEspecialidad = "440";
    } else if (this.especialidadPlano == "comunicaciones") {
      this.numeroEspecialidad = "450";
    } else if (this.especialidadPlano == "ambito") {
      this.numeroEspecialidad = "460";
    }
  }

  //se obtiene el nombre del PDF según los parámetros de ICREA
  obtenerNombrePDF(nombre){
    switch (nombre) {
      case 'CEDULA_DE_OBSERVACIONES_Y_SUGERENCIAS.pdf':
        return this.numeroEspecialidad+'-8 Cédula de observaciones y sugerencias.pdf';
      case 'CEDULA_DE_SEGUIMIENTO.pdf':
        return this.numeroEspecialidad + '-2 Cédula de seguimiento.pdf';
      case 'CONCLUSIONES_TECNICAS.pdf':
        return this.numeroEspecialidad + '-7 Conclusiones técnicas.pdf';
      case 'EQUIPO_DE_MEDICION_UTILIZADO.pdf':
        return this.numeroEspecialidad + '-1 Equipo de medición.pdf';
      case 'EVALUACION_TECNICA.pdf':
        return this.numeroEspecialidad + '-6 Evaluación técnica.pdf';
      case 'GUIA_DE_REVISION_DE_CUMPLIMIENTO.pdf':
        return this.numeroEspecialidad+'-9 Guía de cumplimiento.pdf';
      case 'INFRAESTRUCTURA_Y_GRAFICAS.pdf':
        return this.numeroEspecialidad + '-5 Infraestructura y gráficas.pdf';
      case 'REPORTE_FOTOGRAFICO.pdf':
        return this.numeroEspecialidad + '-3 Reporte Fotográfico.pdf';
      case 'REPORTE_TERMOGRAFICO.pdf':
        return this.numeroEspecialidad + '-4 Reporte Termográfico.pdf';
      default:
      return "Documento no reconocido.pdf"
    }
  }

  
  //se obtiene el nombre del ZIP según los parámetros de ICREA
  obtenerNombreZIP(){
    switch (this.numeroEspecialidad) {
      case '420':
        return 'Documento 4 '+this.numeroEspecialidad+' Reporte Eléctrico.zip';
      case '430':
        return 'Documento 4 '+this.numeroEspecialidad + ' Reporte de climatización.zip';
      case '440':
        return 'Documento 4 '+this.numeroEspecialidad + ' Reporte de seguridad.zip';
      case '450':
        return 'Documento 4 '+this.numeroEspecialidad + ' Reporte de comunicaciones.zip';
      case '460':
        return 'Documento 4 '+this.numeroEspecialidad + ' Reporte de ámbito.zip';
      default:
      return "Documento no reconocido.zip"
    }
  }

  insertarLog(pdfName,tipo){
    let datos={
      user:this.us.user,
      descripcion:tipo=="PDF"?"Generó el documento PDF de "+this.obtenerNombrePDFLog(pdfName)+" de la especialidad "+this.especialidad+".":"Generó el archivo .zip que contiene todos los "+this.obtenerNombrePDFLog(pdfName)+" de la especialidad "+this.especialidad+".",
      auditoria:this.auditoria.numero
    };
    this.loginService.insertarLog(datos).subscribe (
      datos=>{
        console.log(datos);
        //verifica que el token sea correcto
        if (datos['estado']=="Exitoso"){
            //Se debe implementar la rutina para redireccionamiento a la dashboard  
        }
      }
    );
  }

  //se obtiene el nombre del PDF según los parámetros de ICREA
  obtenerNombrePDFLog(nombre){
    if (nombre.includes('Cédula de observaciones y sugerencias')){
        return 'Cédula de observaciones y sugerencias';
    } else if (nombre.includes('Cédula de seguimiento')){
        return 'Cédula de seguimiento';
    } else if (nombre.includes('Conclusiones técnicas')){
        return 'Conclusiones técnicas';
    } else if (nombre.includes('Equipo de medición')){
        return 'Equipo de medición';
    } else if (nombre.includes('Evaluación técnica')){
        return 'Evaluación técnica';
    } else if (nombre.includes('Guía de cumplimiento')){
        return 'Guía de cumplimiento';
    } else if (nombre.includes('Infraestructura y gráficas')){
        return 'Infraestructura y gráficas';
    } else if (nombre.includes('Reporte Fotográfico')){
        return 'Reporte Fotográfico';
    } else if (nombre.includes('Reporte termográfico')){
        return 'Reporte Termográfico';
    } else if (nombre.includes('Documentos complementarios')){
      return 'Documentos complementarios';
    } else if (nombre.includes('Documento 4 ')){
      return 'Reportes';
    } else{
        return 'Documento no reconocido';
    }
  }
}
