//import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import 'rxjs/add/operator/filter';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Location, PopStateEvent } from '@angular/common';
import PerfectScrollbar from 'perfect-scrollbar';
import * as $ from "jquery";
import { Component, ViewChild, OnInit } from '@angular/core';
import { Observable, Subject, fromEvent, interval, combineLatest } from 'rxjs';
import { switchMap, takeUntil, tap, filter, map } from 'rxjs/operators';
import { LoginServiceService } from 'src/app/login-service.service';


@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.css']
})
export class AdminLayoutComponent implements OnInit {
  private _router:Subscription;
  private lastPoppedUrl: string;
  private yScrollStack: number[] = [];
  @ViewChild('container', { static: true })
  $container;
  userActions$: Observable<any>;
  counter$ = interval(1000);
  routes$ = this.router.events.pipe(filter(x => x instanceof NavigationEnd));
  active$ = new Subject<boolean>();

  constructor(public location: Location, private router:Router, public loginservice: LoginServiceService) { }

  ngOnInit(): void {
    const isWindows = navigator.platform.indexOf('Win') > -1 ? true : false;

    this.loginservice.validarHora(this.router,this.loginservice.TIEMPO_INACTIVIDAD);
    this.userActions$ = combineLatest(
        fromEvent(this.$container.nativeElement, 'mousemove'),
        this.routes$
      ).pipe(
        map(([mouseMove, route]) => ({ mouseMove, route })),
        switchMap(action => this.counter$.pipe(
          tap(x => { 
            if (x === this.loginservice.TIEMPO_INACTIVIDAD) { // En X segundos de inactividad desplegara una alerta.
              this.active$.next(false);
              alert('Usuario inactivo por mucho tiempo');
              //this.router.navigateByUrl('/inicio');
              //console.log('10 segundos de inactividad.');
              this.loginservice.validarHora(this.router,0); 
            }else{
                if (x%10 == 0){ //Cada 10 segundos valida que la sesion este actiova en el servidor
                    this.loginservice.validarHora(this.router,this.loginservice.TIEMPO_INACTIVIDAD/60);
                    this.loginservice.verificarLogin().subscribe(
                        datos=>{
                            if (datos['estado']!="Activo"){
                                this.loginservice.validarHora(this.router,0); 
                            }
                          }
                    );
                }
            }
          })
        )),
        takeUntil(this.active$)
      );
      
    if (this.loginservice.estadoProcesoInactividad==false){
        this.userActions$.subscribe(console.log);
        this.loginservice.estadoProcesoInactividad=true;
    }
      //this.userActions$.subscribe();
    

    if (isWindows && !document.getElementsByTagName('body')[0].classList.contains('sidebar-mini')) {
      // if we are on windows OS we activate the perfectScrollbar function

        document.getElementsByTagName('body')[0].classList.add('perfect-scrollbar-on');
    } else {
        document.getElementsByTagName('body')[0].classList.remove('perfect-scrollbar-off');
    }

    const elemMainPanel = <HTMLElement>document.querySelector('.main-panel');
    const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');

    this.location.subscribe((ev:PopStateEvent) => {
        this.lastPoppedUrl = ev.url;
    });
   this.router.events.subscribe((event:any) => {
      if (event instanceof NavigationStart) {
         if (event.url != this.lastPoppedUrl)
             this.yScrollStack.push(window.scrollY);
     } else if (event instanceof NavigationEnd) {
         if (event.url == this.lastPoppedUrl) {
             this.lastPoppedUrl = undefined;
             window.scrollTo(0, this.yScrollStack.pop());
         } else
             window.scrollTo(0, 0);
     }
  });
  this._router = this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: NavigationEnd) => {
      elemMainPanel.scrollTop = 0;
      elemSidebar.scrollTop = 0;
  });
  if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      let ps = new PerfectScrollbar(elemMainPanel);
      ps = new PerfectScrollbar(elemSidebar);
  }

      const window_width = $(window).width();
      let $sidebar = $('.sidebar');
      let $sidebar_responsive = $('body > .navbar-collapse');
      let $sidebar_img_container = $sidebar.find('.sidebar-background');

      if(window_width > 767){
          if($('.fixed-plugin .dropdown').hasClass('show-dropdown')){
              $('.fixed-plugin .dropdown').addClass('open');
          }

      }

      $('.fixed-plugin a').click(function(event){
        // Alex if we click on switch, stop propagation of the event, so the dropdown will not be hide, otherwise we set the  section active
          if($(this).hasClass('switch-trigger')){
              if(event.stopPropagation){
                  event.stopPropagation();
              }
              else if(window.event){
                 window.event.cancelBubble = true;
              }
          }
      });

      $('.fixed-plugin .badge').click(function(){
        let $full_page_background = $('.full-page-background');


        $(this).siblings().removeClass('active');
        $(this).addClass('active');

        var new_color = $(this).data('color');

        if($sidebar.length !== 0){
            $sidebar.attr('data-color', new_color);
        }

        if($sidebar_responsive.length != 0){
            $sidebar_responsive.attr('data-color',new_color);
        }
    });

    $('.fixed-plugin .img-holder').click(function(){
      let $full_page_background = $('.full-page-background');

      $(this).parent('li').siblings().removeClass('active');
      $(this).parent('li').addClass('active');


      var new_image = $(this).find("img").attr('src');

      if($sidebar_img_container.length !=0 ){
          $sidebar_img_container.fadeOut('fast', function(){
             $sidebar_img_container.css('background-image','url("' + new_image + '")');
             $sidebar_img_container.fadeIn('fast');
          });
      }

      if($full_page_background.length != 0){

          $full_page_background.fadeOut('fast', function(){
             $full_page_background.css('background-image','url("' + new_image + '")');
             $full_page_background.fadeIn('fast');
          });
      }

      if($sidebar_responsive.length != 0){
          $sidebar_responsive.css('background-image','url("' + new_image + '")');
      }
  });
  }

  ngAfterViewInit() {
      this.runOnRouteChange();
  }
  isMaps(path){
      var titlee = this.location.prepareExternalUrl(this.location.path());
      titlee = titlee.slice( 1 );
      if(path == titlee){
          return false;
      }
      else {
          return true;
      }
  }
  runOnRouteChange(): void {
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      const elemMainPanel = <HTMLElement>document.querySelector('.main-panel');
      const ps = new PerfectScrollbar(elemMainPanel);
      ps.update();
    }
  }
  isMac(): boolean {
      let bool = false;
      if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
          bool = true;
      }
      return bool;
  }

}
