import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import {LoginServiceService} from '../login-service.service';
import swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cambioc',
  templateUrl: './cambioc.component.html',
  styleUrls: ['./cambioc.component.css']
})
export class CambiocComponent implements OnInit {
  
  public contactForm: FormGroup;
  public active: boolean = false;
  public cerrarSesion: boolean;
  public advertencia: boolean;
  public errorc: boolean; // mensaje error contrasena
  public exitoso: boolean;
  public msj: string;

  hide = true;
  hideU = true;

  usuario={
    idmd5:null,
    pass:null
  }

  contra ={
    pass1:null,
    pass2:null
  }


  constructor(private loginService: LoginServiceService, private route: Router) {
    if (localStorage.getItem("usuario") != null) {
      //recuperamos el id del usuario
      let user=JSON.parse(localStorage.getItem("usuario"));
      this.usuario.idmd5=user.id;
    }
   }

  createForm() {
    return new FormGroup({
      password: new FormControl('', [Validators.required, Validators.minLength(5)]),
      contrasena: new FormControl('', [Validators.required, Validators.minLength(5)]),
    });
  }

  onResetForm(): void {
    this.contactForm.reset();
  }

  onSaveForm(): void {
    this.active = true;
    if (this.contactForm.valid) {
      //Se valida que las contraseña son iguales
      if(this.contra.pass1 == this.contra.pass2){
        //guardamos la contraseña en el arreglo usuario
        this.usuario.pass=this.contra.pass1;
          //realizamos la consulta del servicio para guardar los cambios de la contraseña
          this.loginService.guardarContra(this.usuario).subscribe (
            datos =>{
              this.active=false;
              switch (datos['estado']){
                case "Exitoso":
                  // acceso a la pantalla que corresponda segun el perfil de usuario
                  let user=JSON.parse(localStorage.getItem("usuario"));
                  user.pass=datos["pass"];
                  user.fecInicio=new Date;
                  localStorage.setItem("usuario",JSON.stringify(user));
                  this.almacenarLog();
                  this.redireccionar();
                  break;
                default:
                  //mostramos el error que se tubo 
                  this.alerta("error",datos['estado']);
                  break;
                }
            }
          );
      }else {
        this.active=false;
        this.alerta("importante","Las contraseñas deben ser iguales");
      }
    }
    else {
      //mostramos el mensaje de advertencia campos incorrectos
      this.active=false;
      this.alerta("importante","Campos incorrectos");
     }
  }

  redireccionar(){
    this.almacenarLog1();
    this.logOut();
    localStorage.removeItem("usuario");
    // se debe redireccionar a la pantalla de inicio
    this.route.navigateByUrl('/inicio');
  }

  almacenarLog1(){
    //Verifica que la informacion este disponible en el LocalStore
    if (localStorage.getItem("usuario") != null) {
      let user=JSON.parse(localStorage.getItem("usuario"));
      let datos={
        user:user["user"],
        descripcion:"Cerro sesión."
        //descripcion:"Agrego la norma "+ this.norma.numero + "."
      };
      //console.log(datos);
      //envia la informacion al servicio para insertar registro en el log
      this.loginService.insertarLog(datos).subscribe (
        datos=>{
          //verifica que el token sea correcto
          if (datos['estado']=="Exitoso"){
              //Se debe implementar la rutina para redireccionamiento a la dashboard  
          }
        }
      );
    }
  }

  logOut(){
    //Verifica que la informacion este disponible en el LocalStore
    if (localStorage.getItem("usuario") != null) {
      let user=JSON.parse(localStorage.getItem("usuario"));
      let datos={
        user:user["user"]
      };
      //envia la informacion al servicio para insertar registro en el log
      this.loginService.logoutUsuario(datos).subscribe (
        datos=>{
          if (datos['estado']=="Exitoso"){
              //accion de ser necesaria
          }
        }
      );
    }
  }

  ngOnInit(): void {
    this.contactForm = this.createForm();
  }

  //Funcion que muestra el mensaje Cerrar Sesion
  mostrarCerrarSesion(){
    this.cerrarSesion = true;
  }

  onClickMe() {
    this.advertencia = false;
    this.errorc = false;
    this.exitoso = false;
    this.cerrarSesion = false;
  }

  get contrasena() { return this.contactForm.get('contrasena'); }
  get password() { return this.contactForm.get('password'); }

  //Funcion que cierra correctamente la sesion
  confirmar(){
    //Borramos la informacion del localStorage
    localStorage.removeItem("usuario");
    //Redireccionamos a la pagina de inicio.
    this.route.navigateByUrl('/inicio');
  }

  //Funcion que muestra los mensajes
  alerta(tipo:string,arg1: string) {
    this.msj = arg1;
    if(tipo == "importante"){
      this.advertencia = true;
    }
    if(tipo == "error"){
      this.errorc = true;
    }
    if(tipo == "exitoso"){
      this.exitoso = true;
    }
  }
  almacenarLog(){
    //Verifica que la informacion este disponible en el LocalStore
    if (localStorage.getItem("usuario") != null) {
      let user=JSON.parse(localStorage.getItem("usuario"));
      let datos={
        user:user["user"],
        descripcion:"Cambio de contraseña."
        //descripcion:"Agrego la norma "+ this.norma.numero + "."
      };
      //console.log(datos);
      //envia la informacion al servicio para insertar registro en el log
      this.loginService.insertarLog(datos).subscribe (
        datos=>{
          //verifica que el token sea correcto
          if (datos['estado']=="Exitoso"){
              //Se debe implementar la rutina para redireccionamiento a la dashboard  
          }
        }
      );
    }
  }

}
