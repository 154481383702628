<!-- TEXTO DEL NOMBRE-->

<nav class="navbar navbar-expand-lg  navbar-absolute fixed-top">
    
    <div class="container-fluid" style="font-family: 'AvenirLTProMedium';" >
        <div class="navbar-wrapper">
          <a class="navbar-brand textoP"style=" cursor:default;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;" >{{getTitle()}}</a>
          <img src="../../../assets/img/logoicreablanco.png" class ="Logo" > 
        </div>
        

        <button mat-raised-button class="navbar-toggler" type="button" (click)="sidebarToggle()">
            <span class="sr-only">Toggle navigation</span>
            <span class="navbar-toggler-icon icon-bar"></span>
            <span class="navbar-toggler-icon icon-bar"></span>
            <span class="navbar-toggler-icon icon-bar"></span>
        </button>
        <div class="collapse navbar-collapse justify-content-end" id="navigation">
            
            <ul class="navbar-nav">
                <li class="nav-item">
                    <a class="nav-link" href="javascript:void(0)">
                        
                        <p>
                            <span class="d-lg-none d-md-block">Account</span>
                        </p>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</nav>
