<head>
  <title></title>
  <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1" />

  <!-- Los iconos tipo Solid de Fontawesome-->
  <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.0.8/css/solid.css">
  <script src="https://use.fontawesome.com/releases/v5.0.7/js/all.js"></script>


</head>

<!-- Mensaje advertencia -->
<div *ngIf="advertencia" class="contenedoradvertencia"></div>
<div *ngIf="advertencia" class="contenedoradvertencia1">
  <div class="contenedorMensaje">
    <div class="btonalarma">
      <img type="button" (click)="onClickMe()" src="assets/img/btn_warning.png" width="45px;" alt="" />
    </div>
    <div class="encabezadoalarma">
      <img src="assets/img/en_warningp.png" width="375px;" alt="warning" />
    </div>
    <div class="cuerpoalarma" style="
          font-family: 'AvenirLTProMedium';
          font-size: 25px;
          text-align: center;
        ">
      <p>{{ msj }}</p>
    </div>
  </div>
</div>
<!-- Fin mensaje advertencia -->

<!-- Gif Loading-->
<div *ngIf="active" class="contenedoradvertencia"></div>
<div *ngIf="active" class="contenedoradvertencia1">
  <div class="loading1"><img src="assets/img/loading.gif" width="200px" alt="splash"></div>
</div>

<div class="main-content" style="background-image: url(./assets/img/fondo1.png);background-size: cover;">
  <div class="container-fluid">
    <div class="col-md-12">
      <div class="card opacity">
        <div class="card-header card-header-danger" style="background-color:#82A412;">
          <h4 class="card-title "
            style="text-align: left;font-family: 'AvenirLTProBlack'; font-size: 15px; color: white;">Reportes de la
            auditoría</h4>
          <p class="card-category" style="text-align: left;font-family: 'AvenirLTProMediumOblique'; color: white;">
            Consulte los reportes {{especialidad}} de la auditoría</p>
        </div>
        <div class="card-body ">
          <div class="row">
            <div class="col-md-12">
              <div class="row">

                <div class="col-md-6">
                  <p style="color: #2B405A; font-family: 'AvenirLTProBlack'; font-size: 18px;"><strong><em>Datos
                        iniciales</em></strong></p>

                </div>

                <div class="col-md-6 d-none d-md-block">

                  <p class="align-right"> <span
                      style="color: #2B405A; font-family: 'AvenirLTProHeavy'; font-size: 18px;">Estado
                      auditoría</span>
                  </p>
                </div>

                <div class="col-md-8">
                  <p> <span style="color: #2B405A; font-family: 'AvenirLTProHeavy'; font-size: 18px;">Número
                      de auditoría:</span>
                    <span
                      style="color: #778595; padding-left: 10px; font-size: 18px;font-family: 'AvenirLTProMediumOblique';">{{auditoria.numero}}
                    </span>
                  </p>

                </div>

                <div class="col-md-4 d-none d-md-block">
                  <p class="align-right">
                    <span
                      style="color: #778595; padding-left: 10px; font-size: 18px;font-family: 'AvenirLTProMediumOblique';">{{auditoria.estado==6
                      ? 'En proceso (Descargando datos)': auditoria.estado==7 ? 'En proceso (Auditores)' : auditoria.estado==8 ? 'Revisión física terminada' :'Finalizada'
                      }}
                    </span>
                  </p>
                </div>

                <div class="col-12 d-block d-md-none">

                  <p class="align-right"> <span
                      style="color: #2B405A; font-family: 'AvenirLTProHeavy'; font-size: 18px;">Estado
                      auditoría: &nbsp;</span>
                    <span
                      style="color: #778595; padding-left: 10px; font-size: 18px;font-family: 'AvenirLTProMediumOblique';">{{auditoria.estado==6
                      ? 'En proceso (Descargando datos)': auditoria.estado==7 ? 'En proceso (Auditores)' : auditoria.estado==8 ? 'Revisión física terminada' :'Finalizada'
                      }}
                    </span>
                  </p>
                </div>
              </div>

              <div class="row">
                <div class="col-md-8">
                  <p> <span style="color: #2B405A; font-family: 'AvenirLTProHeavy'; font-size: 18px;">Nombre
                      de la empresa:&nbsp;</span>
                    <span
                      style="color: #778595; padding-left: 10px; font-size: 18px;font-family: 'AvenirLTProMediumOblique';">{{auditoria.empresa}}
                    </span>
                  </p>
                </div>

                <div class="col-md-4 disp-flex">
                  <span class="notification" *ngIf="this.auditoria.estado!=8" style="color: white; font-family: 'AvenirLTProHeavy';font-size: 15px; background-color: #98B14C; padding: 1px 4px;;border-radius: 7px;
                      text-align: center;"><em>{{auditoria.diferenciaDias}}
                      días para finalizar auditoría</em></span>
                </div>
              </div>
            </div>



          </div>


          <div class="row">
            <div class="col-md-12">
              <div align="center">
                <hr style="border-color:rgba(43, 64, 90, 1); margin-left: -20px; margin-right: -20px;margin-top: 0px;">

              </div>
              <label style="color: #2B405A; font-family: AvenirLTProLight;margin-top: -10px; font-size: 20px;">Al dar
                click en el ícono descargará el archivo solicitado</label>
              <div align="center" class="row" style="margin-top: 0px;">

                <div class="col-sm-6 col-md-3" *ngIf="equipoMedicion" style="margin-top: 30px;" (click)="obtenerPDF('EQUIPO DE MEDICIÓN')">
                  <a style="display: flex; flex-direction: column; height: 100%;">
                    <label style="color: #2B405A; font-family: AvenirLTProHeavy;font-size: 18px;">EQUIPO DE
                      MEDICIÓN</label>
                    <div style="display: flex; justify-content: center; cursor: auto; flex: 1; align-items: flex-end;">
                      <div class="card-icon"
                        style="background-size: 70px 70px; background-image: url(./../../assets/img/equipoMedicion.png); width: 70px; height: 70px; cursor: pointer;">
                      </div>
                    </div>
                  </a>
                </div>

                
                <div class="col-sm-6 col-md-3" *ngIf="!equipoMedicion" style="margin-top: 30px;">
                  <a style="display: flex; flex-direction: column; height: 100%;">
                    <label style="color: #2B405A; font-family: AvenirLTProHeavy;font-size: 18px;">EQUIPO DE
                      MEDICIÓN</label>
                    <div style="display: flex; justify-content: center; cursor: default; flex: 1; align-items: flex-end;">
                      <div class="card-icon"
                        style="background-size: 70px 70px; background-image: url(./../../assets/img/ic_medicion_disable.png); width: 70px; height: 70px;">
                      </div>
                    </div>
                  </a>
                </div>

                <div class="col-sm-6 col-md-3" *ngIf="reporteCedula" style="margin-top: 30px;"
                  (click)="obtenerPDF('CÉDULA DE SEGUIMIENTO')">
                  <a style="display: flex; flex-direction: column; height: 100%;">
                    <label style="color: #2B405A; font-family: AvenirLTProHeavy;font-size: 18px;">CÉDULA DE
                      SEGUIMIENTO</label>
                    <div style="display: flex; justify-content: center; cursor: auto; flex: 1; align-items: flex-end;">
                      <div class="card-icon"
                        style="background-size: 70px 70px; background-image: url(./../../assets/img/cedulaSeguimiento_V.png); width: 70px; height: 70px; cursor: pointer;">
                      </div>
                    </div>
                  </a>
                </div>

                <div class="col-sm-6 col-md-3" *ngIf="!reporteCedula" style="margin-top: 30px;">
                  <a style="display: flex; flex-direction: column; height: 100%;">
                    <label style="color: #2B405A; font-family: AvenirLTProHeavy;font-size: 18px;">CÉDULA DE
                      SEGUIMIENTO</label>
                    <div style="display: flex; justify-content: center; cursor: default; flex: 1; align-items: flex-end;">
                      <div class="card-icon"
                        style="background-size: 70px 70px; background-image: url(./../../assets/img/cedulaSeguimiento_g.png); width: 70px; height: 70px;">
                      </div>
                    </div>
                  </a>
                </div>

                <div class="col-sm-6 col-md-3" style="margin-top: 30px;" (click)="obtenerPDF('REPORTE FOTOGRÁFICO')">
                  <a style="display: flex; flex-direction: column; height: 100%;">
                    <label style="color: #2B405A; font-family: AvenirLTProHeavy;font-size: 18px;">REPORTE
                      FOTOGRÁFICO</label>
                    <div style="display: flex; justify-content: center; cursor: auto; flex: 1; align-items: flex-end;">
                      <div class="card-icon"
                        style="background-size: 70px 70px; background-image: url(./../../assets/img/reporteFotografico.png); width: 70px; height: 70px; cursor: pointer;">
                      </div>
                    </div>
                  </a>
                </div>

                <div class="col-sm-6 col-md-3" *ngIf="reporteTermografico" style="margin-top: 30px;"
                  (click)="obtenerPDF('REPORTE TERMOGRÁFICO')">
                  <a style="display: flex; flex-direction: column; height: 100%;">
                    <label style="color: #2B405A; font-family: AvenirLTProHeavy;font-size: 18px;">REPORTE
                      TERMOGRÁFICO</label>
                    <div style="display: flex; justify-content: center; cursor: auto; flex: 1; align-items: flex-end;">
                      <div class="card-icon"
                        style="background-size: 70px 70px; background-image: url(./../../assets/img/reporteTermografico_V.png); width: 70px; height: 70px; cursor: pointer;">
                      </div>
                    </div>
                  </a>
                </div>

                <div class="col-sm-6 col-md-3" *ngIf="!reporteTermografico" style="margin-top: 30px;">
                  <a style="display: flex; flex-direction: column; height: 100%;">
                    <label style="color: #2B405A; font-family: AvenirLTProHeavy;font-size: 18px;">REPORTE
                      TERMOGRÁFICO</label>
                    <div style="display: flex; justify-content: center; cursor: default; flex: 1; align-items: flex-end;">
                      <div class="card-icon"
                        style="background-size: 70px 70px; background-image: url(./../../assets/img/reporteTermografico_g.png); width: 70px; height: 70px;">
                      </div>
                    </div>

                  </a>
                </div>
                <div class="col-sm-6 col-md-3" *ngIf="infraestructuraGraficas" style="margin-top: 30px;"
                  (click)="obtenerPDF('INFRAESTRUCTURA Y GRÁFICAS')">
                  <a style="display: flex; flex-direction: column; height: 100%;">
                    <label style="color: #2B405A; font-family: AvenirLTProHeavy;font-size: 18px;">INFRAESTRUCTURA Y
                      GRÁFICAS</label>
                    <div style="display: flex; justify-content: center; cursor: auto; flex: 1; align-items: flex-end;">
                      <div class="card-icon"
                        style="background-size: 70px 70px; background-image: url(./../../assets/img/infraestructuraGraficas.png); width: 70px; height: 70px; cursor: pointer;">
                      </div>
                    </div>
                  </a>
                </div>
                
                <div class="col-sm-6 col-md-3" *ngIf="!infraestructuraGraficas" style="margin-top: 30px;">
                  <a style="display: flex; flex-direction: column; height: 100%;">
                    <label style="color: #2B405A; font-family: AvenirLTProHeavy;font-size: 18px;">INFRAESTRUCTURA Y
                      GRÁFICAS</label>
                    <div style="display: flex; justify-content: center; cursor: default; flex: 1; align-items: flex-end;">
                      <div class="card-icon"
                        style="background-size: 70px 70px; background-image: url(./../../assets/img/ic_infraestructura_disable.png); width: 70px; height: 70px;">
                      </div>
                    </div>
                  </a>
                </div>
                <div class="col-sm-6 col-md-3" *ngIf="evaluacionTecnica" style="margin-top: 30px;" (click)="obtenerPDF('EVALUACIÓN TÉCNICA')">
                  <a style="display: flex; flex-direction: column; height: 100%;">
                    <label style="color: #2B405A; font-family: AvenirLTProHeavy;font-size: 18px;">EVALUACIÓN
                      TÉCNICA</label>
                    <div style="display: flex; justify-content: center; cursor: auto; flex: 1; align-items: flex-end;">
                      <div class="card-icon"
                        style="background-size: 70px 70px; background-image: url(./../../assets/img/evaluacionTecnica.png); width: 70px; height: 70px; cursor: pointer;">
                      </div>
                    </div>
                  </a>
                </div>

                
                <div class="col-sm-6 col-md-3" *ngIf="!evaluacionTecnica" style="margin-top: 30px;">
                  <a style="display: flex; flex-direction: column; height: 100%;">
                    <label style="color: #2B405A; font-family: AvenirLTProHeavy;font-size: 18px;">EVALUACIÓN
                      TÉCNICA</label>
                    <div style="display: flex; justify-content: center; cursor: default; flex: 1; align-items: flex-end;">
                      <div class="card-icon"
                        style="background-size: 70px 70px; background-image: url(./../../assets/img/ic_evaluacion_disable.png); width: 70px; height: 70px;">
                      </div>
                    </div>
                  </a>
                </div>
                <div class="col-sm-6 col-md-3" *ngIf="conclusionesTecnicas" style="margin-top: 30px;" (click)="obtenerPDF('CONCLUSIONES TÉCNICAS')">
                  <a style="display: flex; flex-direction: column; height: 100%;">
                    <label style="color: #2B405A; font-family: AvenirLTProHeavy;font-size: 18px;">CONCLUSIONES
                      TÉCNICAS</label>
                    <div style="display: flex; justify-content: center; cursor: auto; flex: 1; align-items: flex-end;">
                      <div class="card-icon"
                        style="background-size: 70px 70px; background-image: url(./../../assets/img/conclusionesTecnicas.png); width: 70px; height: 70px; cursor: pointer;">
                      </div>
                    </div>
                  </a>
                </div>

                <div class="col-sm-6 col-md-3" *ngIf="!conclusionesTecnicas" style="margin-top: 30px;">
                  <a style="display: flex; flex-direction: column; height: 100%;">
                    <label style="color: #2B405A; font-family: AvenirLTProHeavy;font-size: 18px;">CONCLUSIONES
                      TÉCNICAS</label>
                    <div style="display: flex; justify-content: center; cursor: default; flex: 1; align-items: flex-end;">
                      <div class="card-icon"
                        style="background-size: 70px 70px; background-image: url(./../../assets/img/ic_conclusiones_disable.png); width: 70px; height: 70px;">
                      </div>
                    </div>
                  </a>
                </div>

                <div class="col-sm-6 col-md-3" style="margin-top: 30px;"
                (click)="obtenerPDF('OBSERVACIONES Y SUGERENCIAS')">
                  <a style="display: flex; flex-direction: column; height: 100%;">
                    <label style="color: #2B405A; font-family: AvenirLTProHeavy;font-size: 18px;">OBSERVACIONES Y
                      SUGERENCIAS</label>
                    <div style="display: flex; justify-content: center; cursor: auto; flex: 1; align-items: flex-end;">
                      <div class="card-icon"
                        style="background-size: 70px 70px; background-image: url(./../../assets/img/observacionesSugerencias.png); width: 70px; height: 70px; cursor: pointer;">
                      </div>
                    </div>
                  </a>
                </div>

                <div class="col-sm-6 col-md-3" style="margin-top: 30px;"
                  (click)="obtenerPDF('GUÍA DE CUMPLIMIENTO')">
                  <a style="display: flex; flex-direction: column; height: 100%;">
                    <label style="color: #2B405A; font-family: AvenirLTProHeavy;font-size: 18px;">GUÍA DE 
                      CUMPLIMIENTO</label>
                    <div style="display: flex; justify-content: center; cursor: auto; flex: 1; align-items: flex-end;">
                      <div class="card-icon"
                        style="background-size: 70px 70px; background-image: url(./../../assets/img/documentosRevision.png); width: 70px; height: 70px; cursor: pointer;">
                      </div>
                    </div>
                  </a>
                </div>

                <div class="col-sm-6 col-md-3" *ngIf="reporteComplementarios" style="margin-top: 30px;"
                  (click)="obtenerPDF('DOCUMENTOS COMPLEMENTARIOS')">
                  <a style="display: flex; flex-direction: column; height: 100%;">
                    <label style="color: #2B405A; font-family: AvenirLTProHeavy;font-size: 18px;">DOCUMENTOS
                      COMPLEMENTARIOS</label>
                    <div style="display: flex; justify-content: center; cursor: auto; flex: 1; align-items: flex-end;">
                      <div class="card-icon"
                        style="background-size: 70px 70px; background-image: url(./../../assets/img/documentosComplementarios_V.png); width: 70px; height: 70px; cursor: pointer;">
                      </div>
                    </div>
                  </a>
                </div>
                <div class="col-sm-6 col-md-3" *ngIf="!reporteComplementarios" style="margin-top: 30px;">
                  <a style="display: flex; flex-direction: column; height: 100%;">
                    <label style="color: #2B405A; font-family: AvenirLTProHeavy;font-size: 18px;">DOCUMENTOS
                      COMPLEMENTARIOS</label>
                    <div style="display: flex; justify-content: center; cursor: default; flex: 1; align-items: flex-end;">
                      <div class="card-icon"
                        style="background-size: 70px 70px; background-image: url(./../../assets/img/documentosComplementarios_g.png); width: 70px; height: 70px;">
                      </div>
                    </div>
                  </a>
                </div>
                <div class="col-sm-6 col-md-3" *ngIf="todosLosReportes" style="margin-top: 30px; cursor: pointer;"
                  (click)="obtenerPDF('TODOS LOS REPORTES')">
                  <a style="display: flex; flex-direction: column; height: 100%;">
                    <label style="color: #2B405A; font-family: AvenirLTProHeavy;font-size: 18px;">TODOS
                      LOS REPORTES</label>
                    <div style="display: flex; justify-content: center; cursor: auto; flex: 1; align-items: flex-end;">
                      <div class="card-icon"
                        style="background-size: 70px 70px; background-image: url(./../../assets/img/todos_V.png); width: 70px; height: 70px; cursor: pointer;">
                      </div>
                    </div>
                  </a>
                </div>
                <div class="col-sm-6 col-md-3" *ngIf="!todosLosReportes" style="margin-top: 30px; cursor: pointer;">
                  <a style="display: flex; flex-direction: column; height: 100%;">
                    <label style="color: #2B405A; font-family: AvenirLTProHeavy;font-size: 18px;">TODOS
                      LOS REPORTES</label>
                    <div style="display: flex; justify-content: center; cursor: auto; flex: 1; align-items: flex-end;">
                      <div class="card-icon"
                        style="background-size: 70px 70px; background-image: url(./../../assets/img/todosg.png); width: 70px; height: 70px;">
                      </div>
                    </div>
                  </a>
                </div>

              </div>
            </div>
          </div>
          <div class="col-12" style="margin-top: -15px;">
            <div id="btnAtras" class="atras" (click)="agregarAtras()"> </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>